import "../css/linkMediator.css";
import { useState, useEffect } from "react";
import {
  Button,
  Backdrop,
  Card,
  Chip,
  TextField,
  Box,
  CardContent,
  Typography,
  InputLabel,
} from "@mui/material";
import { ReactComponent as TrashIcon } from "../media/icons/trashcan_icon.svg";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import authenticateMediatore from "../services/mediatore/authenticateMediatore";
import removeMediatoreLink from "../services/mediatore/removeMediatoreLink"
import useGetUserInfo from "../hooks/useGetUserInfo";

export default function LinkMediator({ auth_token }) {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [mediatorInformation, setMediatorInformation] = useState();

  const classes = useStyles();

  function onLoginSuccess() {
    setIsUserAuthenticated(true)
    setShowModal(false)
    getUser()

  }

  function onDeleteMediatoreLink(token){
    removeMediatoreLink(token)
    setMediatorInformation()
    getUser()

  }

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  async function getUser() {
      const user = await getUserInformation(auth_token);
      
      if (!user?.user_info?.mediatore_info?.value) {
        console.error('User information is incomplete:', user);
        return;
      }
  
      const { mediatore_info } = user.user_info;
      const { value } = mediatore_info;
      const { name, email, userName } = value;
  
      if (name && email && userName) {
        setMediatorInformation({
          name: name,
          email: email,
          username: userName,
        });
      } 
  }
  

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="link_mediator_container">
      <h1 className="title">Vincular Intermediario</h1>
      <p className="text">
        Lorem ipsum odor amet, consectetuer adipiscing elit. Pharetra nullam
        montes praesent non auctor nunc per ligula.
      </p>

      {mediatorInformation && (
        <MediatorInformation mediator={mediatorInformation} handleDelete={()=> onDeleteMediatoreLink(auth_token)} />
      )}

      {!isUserAuthenticated && (
        <div className="link-mediator-button-container">
          <Button className={classes.buttonLink} onClick={openModal}>
            Vincular
          </Button>
        </div>
      )}
      {showModal && (
        <Backdrop open={showModal}>
          <LoginForm
            closeModal={closeModal}
            onLoginSuccess={onLoginSuccess}
            auth_token={auth_token}
          />
        </Backdrop>
      )}
    </div>
  );
}

const LoginForm = ({ closeModal, onLoginSuccess, auth_token }) => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState(
    "here-goes-an-error-message"
  );

  const validateUsername = (username) => {
    const usernameRegex = /^[a-zA-Z0-9]+$/;
    return usernameRegex.test(username);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    if (!validateUsername(username)) {
      setUsernameError("Usuario no puede tener caracteres especiales");
      isValid = false;
    } else {
      setUsernameError("");
    }

    if (password.length === 0) {
      setPasswordError("contraseña es requerida");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (isValid) {
      let response = await authenticateMediatore(
        username,
        password,
        auth_token
      );

      let data = await response.json();
      if (data.success) {
        onLoginSuccess();
      } else {
        window.alert("Credenciales inválidas");
      }
    }
  };

  return (
    <div>

      <Card sx={{ maxWidth: 400, width: "100%" }}>

        <CardContent>
        <Chip
        label={<span style={{ color: "black" }}>X</span>}
        variant="outlined"
        style={{
          zIndex: 10000,
          margin: "16px",
        }}
        onClick={closeModal}
      />
          <Typography variant="h5" component="h2" gutterBottom>
            Vincularme
          </Typography>
          <form onSubmit={handleSubmit}>
            <InputLabel>Usuario</InputLabel>
            <TextField
              type="text"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              error={!!usernameError}
              helperText={usernameError}
              required
              sx={{ "& .MuiInputBase-input": { color: "black" } }}
            />

            <InputLabel
              htmlFor="outlined-adornment-password"
              className="pass-label"
            >
              Contraseña
            </InputLabel>
            <TextField
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!passwordError}
              helperText={passwordError}
              sx={{ "& .MuiInputBase-input": { color: "black" } }}
              required
            />
            <Box mt={2}>
              <Button type="submit" fullWidth className={classes.buttonLink}>
                Login
              </Button>

              {errorMessage && <Typography>{errorMessage}</Typography>}
            </Box>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};
const MediatorInformation = ({ mediator, handleDelete }) => {
  const { name, email, username } = mediator;
  return (
    <div className="mediator_info_container">
      <div className="organization__info">
        <div className="organization__icon"></div>
        <div>
          <p className="info_item">{`Nombre: ${name}`}</p>
          <p className="info_item">{`Email: ${email}`}</p>
          <p className="info_item">{`Usuario: ${username}`}</p>
        </div>
      </div>
      <IconButton
        onClick={handleDelete}
        style={{ marginLeft: "auto" }}
        children={<TrashIcon />}
      />
    </div>
  );
};

const useStyles = makeStyles({
  buttonLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    padding: "16px 48px",
    borderRadius: "8px",
    backgroundColor: "#8066ec",
    textTransform: "none",
    flexGrow: 0,
    fontFamily: "Figtree",
    fontSize: "18px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#6851c8",
    },
  },
});

async function getUserInformation(token) {
  let API_URL = process.env.REACT_APP_AI_API_URL + "/api/v1/user/";
  const requestInfo = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify(),
  };

  let response = await fetch(API_URL, requestInfo);
  return response.json();
}
