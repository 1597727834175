
import { useState  } from 'react';
const URL_SEND_PIN = `${process.env.REACT_APP_AI_API_URL}/api/v1/require-add-phone-number/`

const REQUEST_INFO = {
    success: false,
    code_requested: false,
    pin_confirmed: false,
}

export default function useRequestPinNumber() {
    const [rqInfo, setRqInfo] = useState(REQUEST_INFO)
    const [errorMessage, setError] = useState(null);

    async function requestPinNumber(phone,code) {
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        const ipData = await ipResponse.json();
        const requestInfo = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "x-real-api": ipData.ip,
                'x-api-key': process.env.REACT_APP_AI_API_KEY,
            },
            body: JSON.stringify({ phone: phone, code: code })
        };

        const response = await fetch(URL_SEND_PIN, requestInfo)

        if(!response.ok){
            let error_response = await response.json()
            setError(error_response.message)
            return
        }

        const response_json = await response.json()

        if (response_json.success) {
            setRqInfo({
                success: true,
                code_requested: true,
                pin_confirmed: response_json.confirmed_pin
            })
        } else {
            setRqInfo({
                code_requested: code ? true : false,
            })
        }

        return response_json
    }

    return {...rqInfo, requestPinNumber, errorMessage}
}