import { useState } from "react"

const API_URL = process.env.REACT_APP_AI_API_URL + "/api/v1/prompt-feedback/"
const FB_INFO = {
  success: false,
}

export default function PromptFeedback() {
  const [fbInfo, setFbInfo] = useState(FB_INFO)

  const promptFeedback = async (
    feedback,
    additionalInfo,
    tagInfo,
    auth_token
  ) => {
    const requestInfo = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": auth_token,
      },
      body: JSON.stringify({
        additional_info: additionalInfo,
        feedback: feedback,
        tag_info: tagInfo,
      }),
    }
    setFbInfo({ ...FB_INFO, isLoading: true })
    await fetch(`${API_URL}`, requestInfo)
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          setFbInfo({
            success: true,
          })
        }
      })
      .catch((error) => {
        setFbInfo((c) => ({ ...c, error }))
      })
  }
  return { ...fbInfo, promptFeedback }
}
