import { useState } from 'react'

const API_URL = process.env.REACT_APP_AI_API_URL + '/api/v1/get-confirmation-code/'
const USER_INFO = {
  successCode: false,
  isLoadingCode: false,
  confirmedCode: false,
  messageCode:false,
}
/**
 * authenticate the user
 */
export default function UserRegistration() {
  const [userInfo, setUserInfo] = useState(USER_INFO)

  const getCode = async (phone, code) => {
    const ipResponse = await fetch('https://api.ipify.org?format=json');
    const ipData = await ipResponse.json();
    const requestInfo = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "x-real-api": ipData.ip,
        'x-api-key': process.env.REACT_APP_AI_API_KEY,
      },
      body: JSON.stringify({
        phone: phone,
        code: code ? code : null
      })
    };
    setUserInfo({ ...USER_INFO, isLoadingCode: true })
    await fetch(`${API_URL}`, requestInfo)
      .then(res => res.json())
      .then(result => {
        if (result.success) {
          setUserInfo({
            successCode: true,
            isLoadingCode: false,
            confirmedCode: result.confirmed_code, 
          })
        } else {
          setUserInfo({
            successCode: false,
            messageCode: result.error,
            confirmedCode:result.confirmed_code, 
            isLoadingCode: false
          })
        }
      })
      .catch(error => {

        setUserInfo(c => ({ ...c, error }))
      })
  }
  return { ...userInfo, getCode }
}
