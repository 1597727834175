import '@fontsource/roboto/500.css';
import '../css/accountState.css';
import '../css/optionsList.css';
import React from 'react';
import PinInput from 'react-pin-input';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import RequirePasswordReset from '../hooks/requirePasswordReset';
import ResetPassword from '../hooks/resetPassword';
import { Stack, Typography } from '@mui/material'
import { FormControl, InputLabel, OutlinedInput } from '@mui/material'
import { InputAdornment, IconButton } from '@mui/material'
import { VisibilityOff, Visibility, Flare } from '@mui/icons-material'
import TextField from '@mui/material/TextField'
import { Alert } from '@mui/material'
import {useStyles, confirmPinStyles} from './passwordResetStyles';
import Logo from '../media/Morado_Lisa_Logo.png'


function PasswordReset({ closeView, displayAlert, setLoading }) {

    const [pin, setPin] = useState();
    const [phone, setPhone] = useState("")
    const [alert, setAlert] = useState()
    const [password, setPassword] = useState()
    const [confirmedPassword, setConfirmedPassword] = useState()
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

    const styles = useStyles()

    const pinContainer = React.useRef();

    const {
        isLoading,
        code_requested,
        pin_confirmed,
        requirePasswordReset,
        error
    } = RequirePasswordReset()

    const {
        isNewPassSet,
        resetPassword
    } = ResetPassword()

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmedPassword = () => setShowConfirmedPassword((show)=> !show)


    function validatePhone(event) {
        const { value } = event.target;
        // Allow only digits and ensure the length is exactly 10
        if (/^\d{0,10}$/.test(value)) {
            setPhone(value);
        } else {
            event.preventDefault();
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (isNewPassSet) {
            displayAlert('Se ha actualizado la contraseña')
            closeView(false)
        }
        if (isLoading) {
            setLoading(true)
        } else (
            setLoading(false)
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNewPassSet, code_requested, pin_confirmed]);


    function onCompletePin(value) {
        setPin(value)
        requirePasswordReset(phone, value)
    }


    if (pin_confirmed) {
        return (
            <React.Fragment>
                {alert}
                <Stack className="form-login" spacing={2}>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password" className='pass-label'>Nueva contraseña *</InputLabel>
                        <OutlinedInput
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                            id="password"
                            className='password'
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Nueva contraseña"
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password" className='pass-label'>Confirmar Contraseña *</InputLabel>
                        <OutlinedInput
                            onChange={e => validatePassword(e.target.value)}
                            value={confirmedPassword ? confirmedPassword.value : ''}
                            id="confirmedPassword"
                            className='password'
                            color={confirmedPassword ? confirmedPassword.color : ''}
                            type={showConfirmedPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmedPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showConfirmedPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirmar Contraseña"
                        />
                    </FormControl>
                    <Button className="login-btn" variant="contained" onClick={() => HandleResetPassword()}>Guardar</Button>
                </Stack>
            </React.Fragment>
        )
    }


    if (code_requested) {
        return (
            <React.Fragment>
                <DivContainer>
                <div style={confirmPinStyles.container} ref={pinContainer}>
                <Stack className="" spacing={2} >
                    <p style={confirmPinStyles.confirmPinTitle}>Ingresa el PIN que te hemos enviado:</p>
                    <PinInput
                        length={4}
                        initialValue=""
                        onChange={(value, index) => { }}
                        type="numeric"
                        inputMode="number"
                        style={{alignSelf: "center", display:'flex', gap:10}}
                        inputStyle={confirmPinStyles.pinInputStyle}
                        onComplete={(value, index) => { onCompletePin(value) }}
                        autoSelect={true}
                        regexCriteria={/^[0-9]*$/}
                    />
                     </Stack>
                </div>
                </DivContainer>
               
            </React.Fragment>
        );
    }

    return (
        <Stack className="form-login" spacing={2}>
        <InputLabel>Número de teléfono:</InputLabel>
        <FormControl
          sx={{ m: 1 }}
          variant="outlined"
          className="phone-number"
        >
          <TextField value={phone} onChange={validatePhone} />
        </FormControl>
        <Button
          className="login-btn"
          variant="contained"
          onClick={() => HandleRequestCode()}
        >
          Restablecer contraseña

        </Button>
        {error && <Typography style={{color: "#dc3545", fontFamily:"Figtree-Regular !important"}}>{error?.message}</Typography>}

      </Stack>
    )

    function HandleRequestCode() {
        if (phone && phone !== '' && phone !== ' ') {
            setAlert()
            requirePasswordReset(phone)
        } else {
            displayAlert('Debes introducir tu número de  teléfono')
        }
    }

    function validatePassword(value) {
        if (password === value) {
            setConfirmedPassword({ 'value': value, 'valid': true, 'color': 'success' })
            setAlert()
            return true
        } else {
            displayAlert('Las contraseña no coincide con la confirmación', 'error')
            setConfirmedPassword({ 'value': value, 'valid': false, 'color': 'error' })
            return false
        }

    }

    function HandleResetPassword() {
        if (confirmedPassword?.valid) {
            resetPassword(phone, pin, confirmedPassword.value)
        } else {
            setAlert(<Alert severity="error">Las contraseña no coincide con la confirmación</Alert>)
        }
    }

}


const DivContainer = (props) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img className="app-logo" src={Logo} alt="logo" />
        <p className={styles.title}>Recupera tu contraseña</p>
      </div>
      {alert}
      {props.children}
    </div>
  );
};


export default PasswordReset;