import { useState, useEffect, useCallback } from "react";
import {
  Card,
  Typography,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  CircularProgress,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Button,
  Alert,
  Stack,
  Chip,
} from "@mui/material";
import Cookies from "universal-cookie";
import useAffiliateUser from "../hooks/useAffiliateUser";
import useGetUserInfo from "../hooks/useGetUserInfo";
import {
  setSessionValue,
  deleteSessionValue,
} from "../services/organizations/SessionManager";
import addCustomerToOrganization from "../services/organizations/addCustomerToOrganization";
import LinkStudentForm from "./LinkStudentForm";
import {getUserEmployeeLink} from "./OrganizationLinking/utilities"
import ValidationQuestionsDialog from "./OrganizationLinking/components/ValidationQuestionsDialog"
import LinkMediatoreForm from "../components/LinkMediatoreForm";
import "../css/linkuserwithorg.css"; // Consider moving to MUI makeStyles or sx prop
import getOrganizationProfiles from "../services/organizations/getOrganizationProfiles"


const DATE = new Date();
const COOKIES_EXPIRE = new Date("12-01-" + (DATE.getFullYear() + 1));
const DOMAIN = process.env.REACT_APP_DOMAIN;
const cookies = new Cookies();

function LinkUserWithOrg({ setDisplay, token }) {
  const [alert, setAlert] = useState();
  const [authOrg, setAuthOrg] = useState("");
  const [affiliate, setAffiliate] = useState(false);
  const [error, setError] = useState(false);
  const [username, setUsername] = useState("");
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [fieldLabel, setFieldLabel] = useState("Usuario");
  const [fieldVisible, setFieldVisible] = useState(false);
  const [qaData, setQaData] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  const [name, setName] = useState("");
  const [cedula, setCedula] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [role, setRole] = useState([]);
  const [email_notification, setEmailNotification] = useState(false);
  const [sms_notification, setSmsNotification] = useState(false);
  const [push_notification, setPushNotification] = useState(false);
  const [step, setStep] = useState(1);
  const [continueButton, setContinueButton] = useState(true);
  const [lastNameError, setLastNameError] = useState(false);
  const [showLinkButton, setShowLinkButton] = useState(false);
  const [openLinkCustomerDialog, setOpenLinkCustomerDialog] = useState(false);
  const [linkCustomerDialogText, setCustomerDialogText] = useState("");
  const [organization, setOrganization] = useState("");
  const [organizations, setOrganizations] = useState([])
  const [currentOrganization, setCurrentOrganization] = useState()
  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const [showProgress, setShowProgress] = useState(false)

  const [userLastName, setUserLastName] = useState("");

  let cleanedCedula;

  const {
    getAffiliateData,
    validateAnswers,
    resultText,
    isLoading,
    openQuestionsDialog,
    openResultsDialog,
    setOpenResultsDialog,
    questionsAnswersData,
    switchAffiliateStatus,
  } = useAffiliateUser();

  const { getUserInfo, user_info, updateUserInfo } = useGetUserInfo();

  const handleOrgChange = (event) => {
    setSelectedOrg(event.target.value);
    setAuthOrg(event.target.value);
    setUsername("");
    setAlert(null);
  };

  const resetStates = () => {
    setStep(1);
    setUsername("");
    setUserLastName("");
    setError(false);
    setAlert();
    setSelectedRole("");
    setSelectedOrg("");
    setShowLinkButton(false);
    setContinueButton(true);
  };

  const handleContinueClick = () => {
    if (
      (selectedRole === "Afiliado" || selectedRole === "Empleado") &&
      step >= 3
    ) {
      setContinueButton(false);
    } else if (selectedRole === "Asegurado" && step >= 4) {
      setContinueButton(false);
    } else if (selectedRole === "Intermediario") {
      setContinueButton(false);
    }

    if (step === 1) {
      if (selectedOrg === "" || selectedOrg === null) {
        setAlert(
          <Alert severity="error">Debe seleccionar una organización</Alert>
        );
        return;
      } else {
        setStep((state) => state + 1);
        setAlert(null);
      }
    }

    if (step === 2) {
      if (selectedRole === "" || selectedRole === null) {
        setAlert(<Alert severity="error">Debe seleccionar un rol</Alert>);
        return;
      } else {
        setStep((state) => state + 1);

        if (selectedRole === "Afiliado" || selectedRole === "Empleado") {
          setShowLinkButton(true);
          setContinueButton(false);
        }

        setAlert(null);
      }
    }

    if (selectedRole === "Asegurado" && step === 3) {
      if (!username) {
        setAlert(
          <Alert severity="error">
            Debe introducir su número de identificación
          </Alert>
        );
        return;
      }

      if (cedula) {
        if (cedula !== username) {
          setAlert(
            <Alert severity="error">
              El número de cédula no coincide con el número de identificación
            </Alert>
          );
          return;
        }
      }
      setLastName("");
      setStep((state) => state + 1);
      setAlert(null);
      setShowLinkButton(true);
      setContinueButton(false);
    }
  };

  if (username) {
    var match = username.match(/\d+/g);
    if (match) {
      cleanedCedula = match.join("");
    }
  }

  function updateUserAffiliate() {
    let info = {
      cedula: username,
    };
    updateUserInfo(token, info);
    addCustomerToOrganization(token, "afp_crecer");
  }

  const handleBackClick = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
      setAlert(null);
    }
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
    setFieldVisible(true);
    setUsername("");
    setAlert(null);
    if (event.target.value === "Afiliado") {
      setFieldLabel("Número de identificación");
    } else if (event.target.value === "Asegurado") {
      setFieldLabel("Número de identificación");
    } else {
      setFieldLabel("Usuario");
    }
  };

  // Nuevo useEffect para controlar los botones
  useEffect(() => {
    if (step === 1) {
      setContinueButton(true);
      setShowLinkButton(false);
    } else if (step === 2) {
      if (selectedRole === "Afiliado" || selectedRole === "Empleado") {
        setShowLinkButton(false);
        setContinueButton(true);
      } else {
        setContinueButton(true);
        setShowLinkButton(false);
      }
    } else if (step === 3) {
      if (selectedRole === "Afiliado" || selectedRole === "Empleado") {
        setShowLinkButton(true);
        setContinueButton(false);
      } else if (selectedRole === "Asegurado") {
        setShowLinkButton(false);
        setContinueButton(true);
      }
    } else if (step === 4) {
      setShowLinkButton(true);
      setContinueButton(false);
    }
  }, [step, selectedRole]);

  useEffect(() => {
    if (switchAffiliateStatus) {
      updateUserAffiliate();
    }
  }, [switchAffiliateStatus]);

  useEffect(() => {
    if (authOrg && username) {
      setAlert(null);
    }

    if (questionsAnswersData) {
      setQaData(questionsAnswersData);
    }
  }, [authOrg, username, questionsAnswersData]);

  useEffect(() => {
    if (!user_info) {
      getUserInfo(token);
    } else {
      setName(user_info.name);
      setLastName(user_info.last_name);
      setCedula(user_info.cedula);
      setEmail(user_info.email);
      setPhone(user_info.phone);
      setCompany(user_info.company);
      setPosition(user_info.position);
      setRole(user_info.role?.[0]);
      setEmailNotification(user_info.email_notification);
      setSmsNotification(user_info.sms_notification);
      setPushNotification(user_info.push_notification);
      setOrganization(user_info.organization);
      setPosition(user_info.position);
    }

    return () => {
      resetStates();
    };
  }, [user_info]);


  useEffect(()=>{
    
    const getProfiles = async (token) =>{
      let profiles = await getOrganizationProfiles(token)
      setOrganizations(profiles)
    }
    try{
      getProfiles(token)
    }catch(ex){
      console.log(ex)
    }
  }, [token])



  const handleAnswerChange = (questionId, answerValue) => {
    setSelectedAnswers((prevState) => ({
      ...prevState,
      [questionId]: answerValue,
    }));
  };

  function handleValidateAnswers() {
    const sendAnswers = Object.values(selectedAnswers);
    validateAnswers(username, sendAnswers);
  }

  const linkUser = async () => {
    setIsDisabledButton(true)
    setShowProgress(true)
    if (!authOrg || !username) {
      setAlert(
        <Alert severity="error">
          Debe seleccionar una organización y un usuario válido.
        </Alert>
      );
      setIsDisabledButton(false)
      setShowProgress(false)
      return;
    }

    if (selectedRole === "Afiliado") {
      if (cedula) {
        if (cedula !== username) {
          setAlert(
            <Alert severity="error">
              El número de cédula no coincide con el número de identificación
            </Alert>
          );
          setIsDisabledButton(false)
          setShowProgress(false)
          return;
        }
      }

      deleteSessionValue("token");
      getAffiliateData(username);
    }

    if (selectedRole === "Asegurado") {
      let response = await getIsUserInsured({
        nu_documento: username,
        type: "CED",
        cd_moneda: "0",
        last_name: lastName,
      });

      if (response.is_insured_user) {
        
        await addCustomerToOrganization(token, currentOrganization.suffix);
        let info = {
          cedula: username,
        };
        
        updateUserInfo(token, info);
        setCustomerDialogText("Ha sido vinculado correctamente");
        setOpenLinkCustomerDialog(true);
        setIsDisabledButton(false)
      } else {
        setCustomerDialogText("No eres asegurado");
        setOpenLinkCustomerDialog(true);
      }
    }

    if (selectedRole === "Empleado") {
      let url = await getUserEmployeeLink(token,email)
      window.location.href = url;
    }
    setIsDisabledButton(false)
    setShowProgress(false)
  };

  const handleValidUser = (event) => {
    const value = event.target.value;

    if (selectedRole === "Asegurado") {
      const idRegex = /^[0-9]{11}$/;

      if (idRegex.test(value) || value === "") {
        setUsername(value);
        setError(false);
      } else {
        setError(true);
      }
    }

    if (selectedRole === "Afiliado") {
      const isValid = /^\d{11}$/.test(value);
      setError(!isValid);
      setUsername(value);
      return;
    } else {
      const isValid = /^[a-zA-Z0-9]*$/.test(value);
      setError(!isValid);
      setUsername(value);
      setEmail(`${value}@${authOrg}`);
      return;
    }
  };

  const handlerLastName = (event) => {
    const { value } = event.target;
    const regex = /^[a-zA-Z]*$/;
    if (regex.test(value)) {
      setLastName(value);
    } else {
      setError();
    }
  };


  return (
    <>
      <Chip
        className="close-terms"
        label={<span>X</span>}
        variant="outlined"
        onClick={() => {
          setDisplay(false);
          resetStates();
        }}
        style={{ zIndex: 10000 }}
      >
        {" "}
      </Chip>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ marginBottom: 5 }}>{alert}</div>
        <div>
          <Card sx={{ padding: "10px" }}>
            <Stack
              sx={{ m: 1, width: "30ch", alignItems: "center" }}
              spacing={2}
            >
              <div
                style={{
                  width: "30ch",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {step === 1 && <p>Seleccione una organización</p>}
                {step === 2 && <p>Seleccione su rol</p>}
                {step === 3 && (
                  <p>
                    {selectedRole === "Intermediario"
                      ? "Autenticación"
                      : selectedRole === "Empleado"
                      ? "Introduzca el usuario de su correo de la empresa"
                      : "Introduzca su identificación"}
                  </p>
                )}
                {step === 4 && <p>Introduzca su apellido</p>}
                {showProgress && (
                  <CircularProgress
                    sx={{
                      color: "#8066EC",
                    }}
                  />
                )}
              </div>

              {step === 1 && (
                <FormControl fullWidth>
                  <Select
                    value={selectedOrg}
                    displayEmpty
                    onChange={handleOrgChange}
                    sx={{ marginBottom: 2 }}
                  >
                    <MenuItem value="">-- Seleccione organización --</MenuItem>
                    {organizations.length > 0 &&
                      organizations.map((org, index) => (
                        <MenuItem
                          onClick={() => setCurrentOrganization(org)}
                          key={index}
                          value={org.domain}
                        >
                          {org.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              {step === 2 && (
                <FormControl fullWidth>
                  <Select
                    value={selectedRole}
                    displayEmpty
                    onChange={handleRoleChange}
                    sx={{ marginBottom: 2 }}
                  >
                    <MenuItem value="">-- Seleccione su rol --</MenuItem>
                    {currentOrganization.roles.map((role, index) => (
                      <MenuItem
                        key={index}
                        value={role.name}
                        disabled={role.disabled}
                      >
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {step === 3 && (
                <>
                  {selectedRole === "Intermediario" ? (
                    <LinkMediatoreForm
                      onCloseModal={() => setDisplay(false)}
                      auth_token={token}
                    />
                  ) : selectedRole === "Estudiante" ||
                    selectedRole === "Prospecto" ? (
                    <LinkStudentForm
                      onCloseModal={() => setDisplay(false)}
                      auth_token={token}
                      selectedRole={selectedRole}
                    />
                  ) : (
                    <FormControl fullWidth>
                      <TextField
                        error={error}
                        helperText={
                          error
                            ? selectedRole === "Afiliado"
                              ? "Número de cédula incorrecto"
                              : "Usuario no válido, no utilice signos o puntos."
                            : ""
                        }
                        label={fieldLabel}
                        value={username}
                        sx={{ mx: 0, my: 0, p: 0 }}
                        onChange={handleValidUser}
                      />

                      {selectedRole === "Empleado"
                        ? authOrg && (
                            <Typography style={{ color: "#15141A" }}>
                              {`@${authOrg}`}
                            </Typography>
                          )
                        : undefined}
                    </FormControl>
                  )}
                </>
              )}

              {step === 4 && (
                <FormControl fullWidth>
                  <TextField
                    label={"Apellido"}
                    value={lastName}
                    sx={{ mx: 0, marginBottom: 0, marginTop: 0, p: 0 }}
                    onChange={handlerLastName}
                    helperText={lastNameError ? "Apellido inválido" : ""}
                  />
                </FormControl>
              )}
            </Stack>
            <div className="modal-settings">
              {step > 1 && (
                <DialogActions>
                  <Button onClick={handleBackClick}>← Regresar</Button>
                </DialogActions>
              )}
              {showLinkButton && (
                <DialogActions>
                  <Button disabled={isDisabledButton} onClick={linkUser}>
                    Vincular ✓
                  </Button>
                </DialogActions>
              )}
              {continueButton && (
                <DialogActions>
                  <Button onClick={handleContinueClick}>Continuar →</Button>
                </DialogActions>
              )}
            </div>
          </Card>

          <ValidationQuestionsDialog
            qaData={qaData}
            openQuestionsDialog={openQuestionsDialog}
            selectedAnswers={selectedAnswers}
            handleAnswerChange={handleAnswerChange}
            handleValidateAnswers={handleValidateAnswers}
          />

          <Dialog open={openResultsDialog}>
            <DialogTitle>Resultado</DialogTitle>
            <DialogContent>
              <p>{resultText}</p>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ color: "#FFF", textTransform: "none" }}
                onClick={() => {
                  resetStates();
                  setOpenResultsDialog(false);
                  setDisplay(false);
                }}
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={openLinkCustomerDialog}>
            <DialogTitle>Resultado</DialogTitle>
            <DialogContent>
              <p>{linkCustomerDialogText}</p>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ color: "#FFF", textTransform: "none" }}
                onClick={() => {
                  resetStates();
                  setOpenLinkCustomerDialog(false);
                  setDisplay(false);
                }}
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>

          <Backdrop
            sx={{ color: "#fff", zIndex: 100, pointerEvents: "auto" }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </div>
    </>
  );
}

const getIsUserInsured = async (values) => {
  let url = `${process.env.REACT_APP_AI_API_URL}/api/v1/is_insured_user/`;

  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(values),
  };

  const request = await fetch(url, options);
  if (request.ok) {
    let response = await request.json();
    return response;
  }
};

export default LinkUserWithOrg;
