import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { MicSharp, MicOffSharp, Phone } from '@mui/icons-material';
import '../css/iconsBar.css';
import '../css/share.css';

import {
    FacebookShareButton,
    WhatsappShareButton,
    EmailShareButton,
    LinkedinShareButton
} from "react-share";


function IconsBar({ shareElement, setShare, switchMode, limited, limitedLink, mode }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setShare(false);
    };

    React.useEffect(() => {
    }, [shareElement])

    const handleClickPhone = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePhone = () => {
        setAnchorEl(null);
    };


    React.useEffect(() => {
    }, [shareElement, anchorEl])

    const openPhone = Boolean(anchorEl);
    const idPhone = openPhone ? 'simple-popover' : undefined;

    const open = Boolean(shareElement);
    const id = open ? 'simple-popover' : undefined;
    const message = 'Conversa con Lisa en:';
    var shareUrl = 'https://lisa.do'
    return (
        <React.Fragment>

            <Popover
                id={id}
                open={open}
                anchorEl={shareElement}
                onClose={handleClose}
                className='share-container'
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ textAlign: 'center', margin: '30px 0px 0px 0px', fontFamily: 'Figtree-Regular', color: '#fff' }}><h1>{'Compartir Lisa'}</h1></Typography>
                <Typography sx={{ textAlign: 'center', margin: '0px 0px 30px 0px', fontFamily: 'Figtree-Regular', color: '#fff' }}>Comparte sobre Lisa en tus redes:</Typography>
                <Stack direction="row" spacing={2}>
                    <FacebookShareButton className='facebook' quote={message} url={shareUrl}>
                        Compartir vía Facebook
                    </FacebookShareButton>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <WhatsappShareButton className='whatsapp' title={message} url={shareUrl}>
                        Compartir vía Whatsapp
                    </WhatsappShareButton>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <LinkedinShareButton className='linkedin' title={message} url={shareUrl}>
                        Compartir vía Linked In
                    </LinkedinShareButton>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <EmailShareButton className="email" subject='Lisa - Afp Crecer' body={message} url={shareUrl}>
                        Compartir vía Correo
                    </EmailShareButton>
                </Stack>

            </Popover>

            {/* {!limited && <div><Phone className='test-icon' onClick={(e) => handleClickPhone(e)} /></div>} */}
            {/* {!limited && !mode && <MicSharp className='home' onClick={() => switchMode()} />}
            {mode && <MicOffSharp className='home' onClick={() => switchMode(false)} />} */}

            <Popover
                id={idPhone}
                open={openPhone}
                anchorEl={anchorEl}
                onClose={handleClosePhone}
                className='share-container'
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}

            >
                <b>Atención al cliente</b>
                <hr />
                <a href='tel:8096884040' target='_parent' ><Phone /> 809-688-4040 </a>

            </Popover>

        </React.Fragment>
    );
}

export default IconsBar