import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Card, Stack } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import Logo from '../media/Morado_Lisa_Logo.png'

const data = [
    {
        'title': '2024-04-02',
        'content': <div>
            <br></br>

            -Actualización visual de Lisa para una apariencia renovada: <br></br>Renovamos la apariencia visual de Lisa para reflejar un diseño más cercano y relevante para los usuarios que interactúan con ella. <br></br><br></br> -Implementación de una voz más realista:<br></br> Hemos utilizado tecnologías avanzadas de síntesis de voz que reproduzcan tonos, inflexiones y entonaciones de manera más natural y cercana a la voz humana. Esto incluye el uso de algoritmos de procesamiento de lenguaje natural y técnicas de aprendizaje automático para mejorar la calidad y la fluidez de la voz de Lisa, lo que permite una comunicación más auténtica y envolvente para los usuarios. <br></br><br></br>

            -Implementación de una interacción totalmente conversacional: <br></br>Hemos eliminado el texto de la conversación en la interfaz para convertir a Lisa en un sistema avanzado de procesamiento de lenguaje natural que permita a los usuarios interactuar con ella de manera natural, como si estuvieran hablando con otro ser humano y proporcionando una sensación de contacto más cercano. <br></br><br></br>
            -Ampliación del concepto de Lisa mediante integración de funciones IA: <br></br><br></br>

            Como parte de nuestra mejora en la utilidad al usuario, hemos ampliado el concepto de Lisa a través de la integración de funcionalidades que ella misma le puede resolver al usuario. Lisa es asistente, experta, representante y consejera; a través de cada una de estas funciones ella va a apoyarme en mi diario vivir: <br></br><br></br>

            Lisa Asistente simplifica la gestión de tu tiempo y agenda diaria, permitiéndote programar reuniones, establecer recordatorios y mantener tu agenda actualizada. <br></br><br></br>

            Lisa Experta está aquí para aclarar cualquier duda que tengas, podrás consultar políticas y procesos, respaldar tus decisiones y alcanzar tus objetivos efectivamente. <br></br><br></br>

            Lisa Representante te brinda apoyo delegando tareas repetitivas, como atender consultas recurrentes de clientes, liberando tu tiempo para actividades más estratégicas. <br></br><br></br>

            Lisa Consejera te ayuda a aumentar tus ingresos perfeccionando tu estrategia, podrás aprender tácticas efectivas para cerrar ventas y alcanzar tus objetivos financieros rápidamente. <br></br><br></br>


            -Integración de módulos de contenidos para activar y desactivar en Lisa: <br></br><br></br>

            En vista de mejorar la usabilidad, hemos integrado la función de módulos de contenido en Lisa, permitiendo a los usuarios seleccionar qué tipo de contenido desean incluir al interactuar con ella, brindando al usuario personalización según sus preferencias y necesidades específicas en el momento.<br></br><br></br>


            -Integración a empleados de organizaciones: <br></br><br></br>

            La integración de Lisa con las organizaciones proporcionará a los empleados un acceso más directo a información interna sobre la empresa en donde laboran, facilitando la aclaración de cualquier duda y promoviendo una mejor comprensión de las políticas corporativas.<br></br><br></br>

         </div>
    },
    {
        'title': '2023-06-01',
        'content': <div>
            <br></br>
            -Expansión de Contenido:<br></br><br></br> Buscamos enriquecer la experiencia del usuario mediante la adición de nuevos contenidos educativos y útiles. Estos contenidos se centrarán en temas de seguridad social, desarrollo profesional, gestión y productividad. Los usuarios tendrán la oportunidad de profundizar su conocimiento en estas áreas, y podrán acceder a estos nuevos recursos fácilmente a través de Lisa.<br></br><br></br>


            -Inteligencia Artificial Generativa: <br></br><br></br>Como parte de nuestra mejora en la interacción con el usuario, hemos implementado la inteligencia artificial generativa para responder a las consultas de los usuarios. Esta IA analiza las preguntas o los casos planteados y genera respuestas pertinentes y precisas. Esto no sólo mejora la eficiencia de nuestra plataforma, sino que también asegura que las respuestas sean útiles y relevantes para los usuarios.<br></br><br></br>


            -Consultas por Voz:<br></br><br></br>En un esfuerzo por hacer nuestra plataforma más accesible y fácil de usar, ahora aceptamos consultas por voz. Los usuarios pueden simplemente hablar sus preguntas en lugar de tener que escribirlas. Esta funcionalidad no sólo mejora la accesibilidad para aquellos que tienen dificultades con la escritura, sino que también permite una interacción más rápida y cómoda para todos nuestros usuarios.<br></br><br></br>


            -Función de Exportación de Respuestas: <br></br><br></br>Para facilitar aún más la interacción con nuestros usuarios, hemos añadido la capacidad de exportar el texto de las respuestas de Lisa por correo electrónico y WhatsApp. Esta función permitirá a los usuarios guardar y revisar las respuestas en su propio tiempo, así como compartirlas fácilmente con otros. Independientemente de si prefieres revisar la información en tu correo electrónico o en tu aplicación de WhatsApp, nos hemos asegurado de que las respuestas estén disponibles para ti en la plataforma que elijas.<br></br><br></br>


            -Asistencia Humana a través de Videollamada: <br></br><br></br>Entendemos que puede haber momentos en los que necesites hablar con una persona real. Por eso, hemos añadido la posibilidad de interactuar con un agente humano a través de videollamadas. Si la Lisa no tiene una respuesta a tu consulta, o si simplemente quieres confirmar la información proporcionada por ella, puedes solicitar una videollamada con uno de nuestros agentes. Esta función garantiza que siempre tendrás acceso a la ayuda que necesitas, y que nunca te sentirás frustrado o confundido por la falta de respuestas.</div>
    },
]

export function Updates() {
    return (
        <React.Fragment>
            <div className='updates-container'>
            <img src={Logo} className='form-logo' alt='AFP crecer'
                            style={{
                                position: 'relative',
                                maxWidth: '100px',
                                zIndex: 11,
                                margin: '0 auto'
                            }}></img>
                <Typography><h1 className='title'>Actualizaciones</h1></Typography>
                <Stack spacing={5}>
                    {data.map((item, index) => {
                        return (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`${index}-content`} 
                                    id={index}
                                    className="accordion-title"
                                    >
                                    {formatDate(item.title)}
                                </AccordionSummary>
                                <AccordionDetails className="content">
                                    {item.content}
                                </AccordionDetails>
                          </Accordion>
                        )
                    })}
                </Stack>
            </div>

        </React.Fragment>
    )

}

function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('es-ES', options);
}