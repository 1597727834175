import { useState } from "react";

const APP_ENV = process.env.REACT_APP_ENV;
const API_URL = process.env.REACT_APP_AI_API_URL + "/api/v1/authenticate/";
const USER_INFO = {
  isLoading: false,
  error: false,
  isAuthenticated: false,
  success: false,
  id: null,
  token: null,
};

export default function Authenticate() {
  const [userInfo, setUserInfo] = useState(USER_INFO);

  const authenticateUser = async (phone, password) => {
    console.log(phone, password)
    const requestInfo = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ phone: phone, password: password }),
    };
    console.log("setUserInfo-1")
    setUserInfo({ ...USER_INFO, isLoading: true });
    console.log("setUserInfo-2")
    try {
      let api_response = await fetch(`${API_URL}`, requestInfo);
      console.log("setUserInfo-3")
      let authentication_response = await api_response.json();
      if (authentication_response.success) {
        setUserInfo({
          isAuthenticated: authentication_response.authenticated,
          id: authentication_response.id,
          success: true,
          token: authentication_response.token,
        });
      } else {
        console.log("setUserInfo-error")
        setUserInfo({
          error: authentication_response.error,
        });
      }
    } catch (error) {
      console.log(error)
      setUserInfo((c) => ({ ...c, error: error, isLoading: false }));
    }
  };

  return { ...userInfo, authenticateUser };
}
