import { TextField, Fab, FormGroup } from "@mui/material";
import React, { useState } from "react";
import { Save } from "@mui/icons-material";
import Stack from '@mui/material/Stack';
import PinInput from 'react-pin-input';
import { useEffect } from 'react';
import useRequestPinNumber from "../hooks/useRequestPinNumber";
import useAddPhoneNumber from "../hooks/useAddPhoneNumber";
import { FormControl } from '@mui/material'
import { Alert } from '@mui/material'
import Button from '@mui/material/Button';


export default function ConfirmPhoneNumber({ url_token }) {
    const [phone, setPhone] = useState(undefined);
    const [error, setError] = useState(null);
    const { code_requested, pin_confirmed, requestPinNumber, errorMessage } = useRequestPinNumber();
    const { requestSavePhoneNumber } = useAddPhoneNumber();


    async function handleRequestPinCode() {
        if (!url_token) {
            setError("Error: 403.");
            return;
        }
        let response = await requestPinNumber(phone);
    }

    async function handleConfirmPhoneNumber(value) {

        if (!url_token) {
            setError("Error: 403");
            return;
        }

        const response = await requestPinNumber(phone, value);
        if (response.confirmed_pin) {
            let response = await requestSavePhoneNumber(url_token, phone);
            if (response.success) {
                window.location.href = `/?result=${url_token}`;
            }
        }
    }

    useEffect(() => {

    }, [code_requested, pin_confirmed]);


    if (error) {
        return <Alert severity="error">{error}</Alert>; // Display error message
    }


    if (code_requested) {
        return (
            <React.Fragment>
                <div className='confirm-pin' style={{ backgroundColor: '#8066ec' }}>
                    <Stack className="form-login" spacing={2} style={{ maxWidth: "350px", color: "white" }}>
                        <p className="title" >Digita el PIN de confirmación que te hemos enviado por SMS</p>
                        <PinInput
                            className="pin-input"
                            length={4}
                            initialValue=""
                            onChange={(value, index) => { }}
                            type="numeric"
                            inputMode="number"
                            style={{ padding: '10px' }}
                            inputStyle={{ borderColor: 'white' }}
                            inputFocusStyle={{ borderColor: 'red' }}
                            onComplete={(value, index) => { handleConfirmPhoneNumber(value) }}
                            autoSelect={true}
                            regexCriteria={/^[0-9]*$/}
                        />
                    </Stack>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div style={{ backgroundColor: '#8066ec' }}>
                <div className="account-settings">
                    <Stack className="form-login" spacing={2} style={{ maxWidth: "350px", color: "white" }}>
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <TextField
                                value={phone}
                                label="Teléfono celular"
                                type="tel"
                                onChange={(e) => {
                                    const input = e.currentTarget.value;
                                    const cleanedInput = input.replace(/[^\d]/g, '').slice(0, 10);
                                    setPhone(cleanedInput);
                                }}
                                inputProps={{ pattern: '[0-9]*', maxLength: 10 }}
                            />

                        </FormControl>
                        <p style={{ color: "red" }}>{errorMessage}</p>
                        <Button className="login-btn" variant="contained" onClick={() => handleRequestPinCode()} >Continuar</Button>
                    </Stack>
                </div>
            </div>
        </React.Fragment>

    );
}
