import axios from "axios";

export default async function deleteUserOrganization(
  token,
  organizationCode,
  organizationRole
) {
  let url = `${process.env.REACT_APP_AI_API_URL}/api/v1/remove_customer_organization/`;

  try {
    const response = await axios({
      method: "delete",
      url: url,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      data: {
        organization: organizationCode,
        role: organizationRole,
      },
    });
    return { data: response.data, error: null, response };
  } catch (e) {
    const error = e.response
      ? `${e.response.status} ${e.response.statusText}`
      : e.message;
    return { data: null, error, response: e.response };
  }
}
