const url = `${process.env.REACT_APP_AI_API_URL}/api/v1/google/login`

export const loginGoogle = async () => {
    await fetch(url, {
        method: 'GET',
    })
    .then(response => {
        return response.json();
    })
    .then(data => {
      window.location.href = data.url;
    })
    .catch((error) => {
      // console.log('Error:', error);
    });
}