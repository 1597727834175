import useStyles from "./OrganizationsStyles";
import { Button, Backdrop } from "@mui/material";
import LinkUserWithOrg from "./LinkUserWithOrg";
import OrganizationLogo from "./OrganizationLogo";
import { useState } from "react";

const ORGANIZATION_LOGO_NAME = "crecer_lab";

const MESSAGE =
  "Estas opciones no están disponibles por el momento, intente más tarde...";

const LinkNewOrganizationButton = ({ authToken }) => {
  const classes = useStyles();
  const [display, setDisplay] = useState(false);

  const handleClickLinkWithOrg = () => {
    setDisplay(true);
  };

  return (
    <div className={classes.buttonLinkContainer}>
      <Button className={classes.buttonLink} onClick={handleClickLinkWithOrg}>
        Vincular nueva organización
      </Button>
      {display && (
        <Backdrop open={display} className="link-org">
          <LinkUserWithOrg setDisplay={setDisplay} token={authToken} />
        </Backdrop>
      )}
    </div>
  );
};

const OrganizationOther = ({ id, name }) => {
  const classes = useStyles();

  const handleClickLinkWithOrg = () => {
    window.alert(MESSAGE);
  };

  return (
    <div className="organization">
      <div className="organization__info">
        <div className="organization__icon">
          <OrganizationLogo name={ORGANIZATION_LOGO_NAME} />
        </div>
        <div>
          <p id={id} className="organization__name">
            {name.toUpperCase()}
          </p>
        </div>
      </div>
      <div className={classes.linkContainer}>
        <span onClick={handleClickLinkWithOrg}>
          <a className={classes.linkLabel}>
            <u>Vincular</u>
          </a>
        </span>
      </div>
    </div>
  );
};

export { LinkNewOrganizationButton, OrganizationOther };
