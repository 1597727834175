import "../css/linkMediator.css";
import { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import authenticateMediatore from "../services/mediatore/authenticateMediatore";

const LinkMediatoreForm = ({ onCloseModal, auth_token }) => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [nuDocument, setNuDocument] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageSucceed, setIsMessageSucceed] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [nuDocumentError, setNuDocumentError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);

  const validateDominicanId = (id) => {
    if (!id) return false;

    // Remove any non-numeric characters
    id = id.replace(/\D/g, "");

    // A valid Cedula should be exactly 11 digits, and RNC should be 9 or 11 digits
    const isCedula = id.length === 11;
    const isRNC = id.length === 9 || id.length === 11;

    if (!isCedula && !isRNC) return false;

    // If it's an RNC, we simply validate the length
    if (isRNC && id.length === 9) return true;

    // Validate Cedula using the modulus 10 algorithm
    let total = 0;
    const weights = [1, 2];

    for (let i = 0; i < 10; i++) {
      let digit = parseInt(id.charAt(i), 10);
      let weight = weights[i % 2];
      let product = digit * weight;

      // If the product is 10 or more, sum the digits (e.g., 12 => 1 + 2)
      total +=
        product >= 10 ? Math.floor(product / 10) + (product % 10) : product;
    }

    let lastDigit = parseInt(id.charAt(10), 10);
    let modulus = total % 10;

    return modulus === 0 ? lastDigit === 0 : lastDigit === 10 - modulus;
  };

  const validateUsername = (username) => {
    const usernameRegex = /^[a-zA-Z0-9]+$/;
    return usernameRegex.test(username);
  };

  const handleUsername = (event) => {
    const { value } = event.target;
    const usernameRegex = /^[a-zA-Z0-9]*$/;

    if (value === "" || usernameRegex.test(value)) {
      setUsernameError(""); // Clear any previous error
      setUsername(value);
    } else {
      setUsernameError("Usuario inválido");
    }
  };

  const handleNuDocument = (event) => {
    const { value } = event.target;

    const re = /^\d{0,11}$/;
    setNuDocumentError("");
    if (!re.test(value)) {
      setNuDocumentError("Número de documento inválido");
      return false;
    }
    setNuDocument(value);
  };

  function handleSucceedLogin() {
    resetForm();
    setIsMessageSucceed(true);
    setErrorMessage("");
    setIsLoginSuccess(true);
  }

  function resetForm() {
    setUsername("");
    setNuDocument("");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    if (!validateDominicanId(nuDocument)) {
      setNuDocumentError("Número de documento es inválido");
      return;
    }

    if (!validateUsername(username)) {
      setUsernameError("Usuario no puede tener caracteres especiales");
      isValid = false;
    } else {
      setUsernameError("");
    }

    if (nuDocument.length === 0) {
      setNuDocumentError("Número de documento es requerida");
      isValid = false;
    } else {
      setNuDocumentError("");
    }

    if (isValid) {
      setIsLoading(true);
      let response = await authenticateMediatore(
        username,
        nuDocument,
        auth_token
      );

      let data = await response.json();
      if (data.success) {
        handleSucceedLogin();
      } else {
        setErrorMessage("Credenciales inválidas");
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoginSuccess ? (
        <>
          <p>Intermediario autenticado con éxito.</p>
          <Button
            style={{ color: "#FFF", textTransform: "none" }}
            onClick={onCloseModal}
          >
            Cerrar
          </Button>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isLoading && <CircularProgress color="success" />}
          </div>
          {isMessageSucceed && (
            <Typography
              style={{
                color: "#269202",
                fontFamily: "Figtree",
                marginBottom: 10,
              }}
            >
              Intermediario autenticado con exito
            </Typography>
          )}
          <InputLabel className={classes.inputLabel}>
            Código de usuario Sirweb
          </InputLabel>

          <TextField
            type="text"
            fullWidth
            margin="normal"
            value={username}
            onChange={handleUsername}
            error={!!usernameError}
            helperText={usernameError}
            required
            sx={{ "& .MuiInputBase-input": { color: "black" } }}
          />

          <InputLabel
            htmlFor="outlined-adornment-password"
            className={classes.inputLabel}
          >
            Número de ID (Cédula o RNC)
          </InputLabel>
          <TextField
            type="text"
            fullWidth
            margin="normal"
            variant="outlined"
            value={nuDocument}
            onChange={handleNuDocument}
            error={!!nuDocumentError}
            helperText={nuDocumentError}
            sx={{ "& .MuiInputBase-input": { color: "black" } }}
            required
          />
          <Box mt={2}>
            <Button type="submit" fullWidth className={classes.buttonLink}>
              Autenticar usuario
            </Button>

            {errorMessage && (
              <Typography
                style={{
                  color: "#ff8661",
                  textAlign: "center",
                  padding: 5,
                }}
              >
                {errorMessage}
              </Typography>
            )}
          </Box>
        </form>
      )}
    </>
  );
};

const useStyles = makeStyles({
  inputLabel: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    padding: 0,
    position: "relative",
    display: "block",
    transformOrigin: "left top 0px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    transition:
      "color 200ms cubic-bezier(0, 0, 0.2, 1), transform 200ms cubic-bezier(0, 0, 0.2, 1), max-width 200ms cubic-bezier(0, 0, 0.2, 1)",
  },

  buttonLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    padding: "16px 48px",
    borderRadius: "8px",
    backgroundColor: "#8066ec",
    textTransform: "none",
    flexGrow: 0,
    fontFamily: "Figtree",
    fontSize: "18px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#6851c8",
    },
  },
});

export default LinkMediatoreForm;
