import { useEffect, useState } from 'react';
import axios from 'axios';

const ENDPOINT_VALIDATE_USER_LINKS = process.env.REACT_APP_AI_API_URL + '/api/v1/validate-user-links/';

const UserOrganizationStatus = ({ token }) => {
    const [status, setStatus] = useState({ error: null, data: null });

    useEffect(() => {
        const fetchUserOrganization = async () => {
            try {
                const { data } = await axios.get(ENDPOINT_VALIDATE_USER_LINKS, {
                    headers: { 'x-access-token': token }
                });
                setStatus({ error: null, data: data });
            } catch {
                setStatus({ error: 'Error al validar el estado del usuario.', data: null });
            }
        };

        fetchUserOrganization();
    }, [token]);

    if (status.error) {
        //window.alert(status.error);
    } else {
        if (status.data && status.data.no_longer_organizations.length > 0){
            let organizations = status.data.no_longer_organizations.map((element) => element.organization)
            let no_longer_organizations_str = organizations.join(", ")
            window.alert(`ya no perteneces a las siguientes organizaciones: ${no_longer_organizations_str}`);
        }
    }

    return (<></>)

};

export default UserOrganizationStatus;
