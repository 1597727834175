import '@fontsource/roboto/500.css';
import '../css/accountState.css';
import '../css/optionsList.css';
import { useParams } from 'react-router-dom';
import React from 'react';
import { useEffect } from 'react';
import { Stack, LinearProgress } from '@mui/material';



function EmailConfirmation() {

    const { id } = useParams();

    console.log(id);
    useEffect(() => {
        if (id !== undefined) {
            confirEmail();
        }
    });

    function confirEmail() {
        fetch(process.env.REACT_APP_AI_API_URL + '/api/v1/confirm-email/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({ params: id })
        })
            .then(function (response) {
                window.location.replace("/");
            })
            .catch(function (error) {

            });
    }




    return (
        <React.Fragment>
            <Stack spacing={2} sx={{ width: '50%', padding: '30px' }} >
                <h4>Estamos confirmando tu correo seras redirigido al completar el proceso</h4>
                <LinearProgress />
            </Stack>
        </React.Fragment>
    );

}

export default EmailConfirmation;