import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Logo from '../media/Morado_Lisa_Logo.png'
import { Stack } from '@mui/material/';

export default function SignUpSteps({ steps }) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = steps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
            <div className='logo-container'>
                <Stack spacing={2}>
                    <img className="form-logo" src={Logo} alt="logo" />
                </Stack>
            </div>
            <Box className="signup-form-header">
                <Typography style={{marginBottom: 10}} className='title'>{steps[activeStep].label}</Typography>
                <p class="header-desc">{steps[activeStep].description}</p>
                {steps[activeStep].element}
            </Box>
            {/* <MobileStepper
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Siguiente
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Atras
                    </Button>
                }
            /> */}
        </Box>
    );
}
