import React, { useEffect, useState } from "react";
import { TextField, Fab, FormControlLabel, FormGroup, Switch, Stack, Alert } from "@mui/material";
import useGetUserInfo from "../hooks/useGetUserInfo";
import '../css/accountSettings.css';

export function AccountSettings(auth_token) {
    const [name, setName] = useState('');
    const [cedula, setCedula] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [email_notification, setEmailNotification] = useState(false);
    const [sms_notification, setSmsNotification] = useState(false);
    const [push_notification, setPushNotification] = useState(false);
    const [alert, setAlert] = useState();

    const { getUserInfo, user_info, updateUserInfo } = useGetUserInfo();

    function handleSave() {
        if (!name || !phone) {
            displayAlert("Por favor completar todos los campos requeridos", "error");
            return;
        }

        let info = {
            name,
            last_name: lastName,
            email,
            cedula,
            phone,
            company,
            position,
            role: null,
            email_notification,
            sms_notification,
            push_notification
        };
        updateUserInfo(auth_token.auth_token, info);
    }

    useEffect(() => {
        if (!user_info) {
            getUserInfo(auth_token.auth_token);
        } else {
            setName(user_info.name);
            setLastName(user_info.last_name);
            setCedula(user_info.cedula);
            setEmail(user_info.email);
            setPhone(user_info.phone);
            setCompany(user_info.company);
            setPosition(user_info.position);
            setEmailNotification(user_info.email_notification);
            setSmsNotification(user_info.sms_notification);
            setPushNotification(user_info.push_notification);
        }
    }, [user_info]);

    const [user_name, setUserName] = useState('');
    const [user_lastname, setLastUserName] = useState('');

    return (
        <React.Fragment>
            <div className="account-settings">
                <div className="avatar">
                    <h4 className="title-name">Hola {user_name || user_info?.name} {user_lastname || user_info?.last_name}!</h4>
                    <p className="subtitle">Esta es tu información Personal</p>
                </div>
                <Stack direction="col" className="settings-form" spacing={2}>
                    <FormGroup>
                        <label>Nombre:</label>
                        <TextField
                            value={name}
                            autoComplete="off"
                            onChange={(e) => {
                                const input = e.currentTarget.value;
                                const filteredInput = input.replace(/[^a-zA-Z\s]/g, '');
                                setName(filteredInput);
                                setUserName(filteredInput);
                            }}
                        />
                        <label>Apellido:</label>
                        <TextField
                            value={lastName}
                            autoComplete="off"
                            onChange={(e) => {
                                const input = e.currentTarget.value;
                                const filteredInput = input.replace(/[^a-zA-Z\s]/g, '');
                                setLastUserName(filteredInput);
                                setLastName(filteredInput);
                            }}
                        />
                        <label>Cédula:</label>
                        <TextField
                            value={cedula}
                            autoComplete="off"
                            disabled={true}
                            onChange={(e) => {
                                const input = e.currentTarget.value;
                                const filteredInput = input.replace(/[^\d-]/g, '');
                                setCedula(filteredInput);
                            }}
                        />
                        <label>Email:</label>
                        <TextField value={email} autoComplete="off" disabled={true} />
                        <label>Teléfono:</label>
                        <TextField value={phone} autoComplete="off" disabled={true} />
                    </FormGroup>

                    <label className="label-notification">Notificaciones</label>
                    <FormGroup>
                        <FormControlLabel
                            labelPlacement="start"
                            control={
                                <Switch
                                    checked={email_notification}
                                    onChange={(e) => setEmailNotification(e.currentTarget.checked)}
                                    sx={{
                                        '& .MuiSwitch-switchBase.Mui-checked': { color: 'green' },
                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#00ff00 !important' },
                                        '& .MuiSwitch-track': { backgroundColor: '#ffffff !important' }
                                    }}
                                />
                            }
                            label="Notificaciones de Email"
                        />
                        <FormControlLabel
                            labelPlacement="start"
                            control={
                                <Switch
                                    checked={sms_notification}
                                    onChange={(e) => setSmsNotification(e.currentTarget.checked)}
                                    sx={{
                                        '& .MuiSwitch-switchBase.Mui-checked': { color: 'green' },
                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#00ff00 !important' },
                                        '& .MuiSwitch-track': { backgroundColor: '#ffffff !important' }
                                    }}
                                />
                            }
                            label="Notificaciones de SMS"
                        />
                        <FormControlLabel
                            labelPlacement="start"
                            control={
                                <Switch
                                    checked={push_notification}
                                    onChange={(e) => setPushNotification(e.currentTarget.checked)}
                                    sx={{
                                        '& .MuiSwitch-switchBase.Mui-checked': { color: 'green' },
                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#00ff00 !important' },
                                        '& .MuiSwitch-track': { backgroundColor: '#ffffff !important' }
                                    }}
                                />
                            }
                            label="Notificaciones Push"
                        />
                        <Fab variant="extended" color="primary" aria-label="add" onClick={handleSave} className="save-button">
                            Guardar Cambios
                        </Fab>
                    </FormGroup>
                </Stack>
                {alert}
            </div>
        </React.Fragment>
    );

    function displayAlert(message, type) {
        setAlert(<Alert className='alert' severity={type}>{message}</Alert>);
        setTimeout(() => {
            setAlert();
        }, 3000);
    }
}

function stringToColor(string) {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}
