import "../css/AnswerFeedback.css"
import "react-chat-elements/dist/main.css"
import { Fab, Card, Chip, TextField, Button, Backdrop } from "@mui/material"
import ThumbUp from "@mui/icons-material/ThumbUpAltRounded"
import ThumbDown from "@mui/icons-material/ThumbDownRounded"
import React, { useState } from "react"
import { useEffect } from "react"
import PromptFeedback from "../hooks/promptFeedback"

export default function FeedBack({ token, onClose }) {
  const [additionalInfo, setAditionalInfo] = useState("")
  const [tagInfo, setTagInfo] = useState([])
  const [disable, setDisable] = useState(true)
  const [feedbackResponseIndex, setFeedbackResponseIndex] = useState(0)
  const [feedbackData, setFeedbackData] = useState([
    {
      name: "positivo",
      icon: <ThumbUp className="feedback-icons" />,
      class: "default",
      checked: false,
    },
    {
      name: "negativo",
      icon: <ThumbDown className="feedback-icons" />,
      class: "default",
      checked: false,
    },
  ])
  const [chipData, setChipData] = useState([
    {
      key: 0,
      label: "Ofensivo/Inseguro",
      color: "default",
      disabled: false,
      icon: "",
    },
    {
      key: 1,
      label: "Irrelevante",
      color: "default",
      disabled: false,
      icon: "",
    },
    {
      key: 2,
      label: "No es objetivamente correcto",
      color: "default",
      disabled: false,
      icon: "",
    },
  ])

  const { promptFeedback } = PromptFeedback()

  const handleClick = (event) => {
    setFeedbackResponseIndex(event.currentTarget.getAttribute("index"))
  }

  const handleSelection = (data) => () => {
    const chips = [...chipData]
    chips[data.key]["color"] =
      chips[data.key]["color"] === "primary" ? "default" : "primary"

    const tagSelected = chips
      .filter((chip) => chip.color === "primary")
      .map((chip) => chip.label)
    setTagInfo(tagSelected)
    setChipData(chips)
  }

  function collectAditionalInformation(feedback) {
    promptFeedback(feedback, additionalInfo, tagInfo, token)
    onClose()
  }

  useEffect(() => {
    setDisable(tagInfo.length === 0 && !additionalInfo)
  }, [tagInfo, additionalInfo])

  const card = (
    <Card
      sx={{
        zIndex: 1000,
        padding: "20px 20px",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        maxWidth: "800px",
      }}
    >
      <p>¿Por qué elegiste esta valoración?</p>
      <div>
        {chipData.map((data) => (
          <Chip
            key={data.key}
            sx={{ margin: "2px" }}
            className="chips"
            variant="outlined"
            label={data.label}
            color={data.color}
            onClick={handleSelection(data)}
            disabled={data.disabled}
          />
        ))}
      </div>
      <TextField
        label="Información adicional"
        onChange={(e) => setAditionalInfo(e.currentTarget.value)}
        color="primary"
        multiline={true}
        rows="5"
        sx={{ width: "100%", marginTop: "20px", backgroundColor: "white" }}
      />
      <Button
        onClick={() =>
          collectAditionalInformation(feedbackData[feedbackResponseIndex]?.name)
        }
        disabled={disable}
        sx={{ marginTop: "20px" }}
      >
        Enviar
      </Button>
      <Button
        onClick={() => onClose()}
        sx={{ marginTop: "20px", color: "red" }}
      >
        Cerrar
      </Button>
    </Card>
  )

  return (
    <React.Fragment>
      <Backdrop
        open={true}
        sx={{ zIndex: 10000, backgroundColor: "transparent" }}
      >
        <div
          style={{
            zIndex: 10000,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card
            className="feedback-dash"
            style={{
              marginTop: "10%",
              padding: "5px 5px",
              backgroundColor: "transparent",
            }}
            variant="outlined"
          >
            {card}
          </Card>
        </div>
      </Backdrop>
    </React.Fragment>
  )
}
