import React, { useEffect, useState, useCallback } from "react";
import { Button, Modal, Box, Typography, IconButton } from "@mui/material";
import useStyles from "./OrganizationsStyles";
import {
  OrganizationOther,
  LinkNewOrganizationButton,
} from "./OrganizationOther";
import OrganizationLogo from "./OrganizationLogo";
import getUserOrganizations from "../services/organizations/getUserOrganizations";
import deleteUserOrganization from "../services/organizations/deleteUserOrganizations";
import { ReactComponent as TrashIcon } from "../media/icons/trashcan_icon.svg";
import "../css/organizations.css";
import O2JSON from "../media/organizations/otherOrganization.json";

const Organization = React.memo(({ organization, onDelete }) => {
  const { id_type, organization_name, role, id } = organization;
  return (
    <div className="organization">
      <div className="organization__info">
        <div className="organization__icon">
          <OrganizationLogo name={organization.code} />
        </div>
        <div>
          <p className="organization__name">
            {`${organization_name.toUpperCase()} - ${role.toUpperCase()}`}
          </p>
          <p className="organization__id">
            {id_type === "email" ? id : `${id_type}: ${id}`.toUpperCase()}
          </p>
        </div>
      </div>
      <IconButton onClick={onDelete} style={{ marginLeft: "auto" }}>
        <TrashIcon />
      </IconButton>
    </div>
  );
});

export default function Organizations({ authToken }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [organizationToDelete, setOrganizationToDelete] = useState(null);

  useEffect(async () => {
    try {
      const { data } = await getUserOrganizations(authToken);
      setOrganizations(data);
    } catch (error) {
      throw ("Error fetching organizations:", error);
    }
  }, [authToken]);

  const openDeleteModal = useCallback((organization) => {
    setOrganizationToDelete(organization);
    setModalMessage(
      "¿Estás seguro que quieres desvincularte de la organización?"
    );
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
    setOrganizationToDelete(null);
  }, []);

  const deleteOrganization = useCallback(async () => {
    if (!organizationToDelete) return;

    try {
      const { data } = await deleteUserOrganization(
        authToken,
        organizationToDelete.code,
        organizationToDelete.role
      );
      if (data?.success) {
        setOrganizations((prev) =>
          prev.filter((org) => !(org.code === organizationToDelete.code && org.role === organizationToDelete.role))
        );
      } else {
        console.error("Error al eliminar la organización.");
      }
    } catch (error) {
      console.error("Error deleting organization:", error);
    } finally {
      closeModal();
    }
  }, [authToken, organizationToDelete, closeModal]);

  return (
    <>
      <div className="organizations_container">
        <div className="organizations">
          <h1 className="organizations__title">Organizaciones Vinculadas</h1>
          <p className="organizations__text">
            Las vinculaciones a empresas te permiten tener información más
            específica cuando hagas preguntas relacionadas a estas empresas a
            Lisa.
          </p>
          <div className="organizations__list">
            {organizations.map((organization) => (
              <Organization
                key={organization.code}
                organization={organization}
                onDelete={() => openDeleteModal(organization)}
              />
            ))}
          </div>
          <div className="other_organizations_list">
            <label className="header">Otras Organizaciones Disponibles</label>
            <div>
              {O2JSON.map((item) => (
                <OrganizationOther id={item.id} name={item.name} authToken={authToken} />
              ))}
            </div>
            <LinkNewOrganizationButton authToken={authToken} />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={classes.modal}
      >
        <Box className={classes.modalContent}>
          <Typography
            id="modal-title"
            variant="h6"
            className={classes.modalTitle}
          >
            Desvincular organización
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalMessage}
          </Typography>
          <Box className={classes.modalActions}>
            <Button
              variant="contained"
              color="primary"
              onClick={deleteOrganization}
              className={classes.modalButton}
            >
              Desvincularme
            </Button>
            <Button onClick={closeModal} className={classes.cancelButton}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
