import { Alert } from '@mui/material'
import { useEffect, useState } from 'react'

function AlertComponent({message, type}) {
    const [alert, setAlert] = useState()


    useEffect(() => {
        if (type === 'error') {

            setAlert(<Alert className='alert' severity="error">{message}</Alert>);
            setTimeout(() => {
                setAlert()
            }, 5000)
    
        } else {
            setAlert(<Alert className='alert' severity="success">{message}</Alert>);
            setTimeout(() => {
                setAlert()
            }, 5000)
    
        }
    }, [message])

    return (
        <div>
        {alert}
        </div>
    )

}

export default AlertComponent