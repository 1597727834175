import { Height } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const BASE_COLOR = {
    main: '#8257E5 !important',
}

const COLORS = {
  primary: BASE_COLOR.main,
  primaryHover: '#6F4BD8',
  secondary: BASE_COLOR.main,
  white: '#fff',
};

const SIZES = {
  paddingSmall: '1rem',
  paddingMedium: '2rem',
  paddingLarge: '2rem',
  borderRadius: '0.5rem',
  fontSizeBase: '1rem',
  boxShadow: '0 0 0.9375rem rgba(0, 0, 0, 0.2)',
  maxWidthModal: '25rem',
  smallBoxShadow: '0 -0.125rem 0.3125rem rgba(0, 0, 0, 0.1)',
};

export const useStyles = makeStyles({
    header:{
        marginBottom: "5rem"
    }, 
    title: {
        color: "#FFFFFF",
        fontSize: 22,
        fontFamily:  "Figtree-Regular !important"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems:"center",
        minHeight: "100vh",
        padding:20,
        backgroundImage:
        "linear-gradient(346deg,#030b16 3.08%,#1b1c3f 97.1%,#8066ec 137.39%)",
    },
    textInput: {

    },
    inputLabel: {
        color: "#FFFFFF", 
        fontFamily: "Figtree-Regular !important",
    },
    button: {
        backgroundColor: "#8066EC !important",
        color: "#FFFFFF",
        fontFamily: "Figtree-Regular !important",
    },

})


export const confirmPinStyles = {
    container: {
        display: "flex",
        justifyContent: "center"
    },

    pinInputStyle:{
        backgroundColor: "#8066ec1a",
        borderRadius: 12, 
        border: '1px solid #8066EC',
        color: "#FFFF",
        width: 64,
        height: 70,
    },
    pinInputFocusStyle: {
        color: "#FFFFFF",
        fontFamily: "Figtree-Regular",
        borderColor: '#8066EC'
    },
    confirmPinTitle:{
        color: "#FFFFFF",
        fontFamily: "Figtree-Regular",
        textAlign: "center"
    }
}

