import Lottie from "react-lottie";

export default function AnimatedLoadingStream({option, label}) {
    return (  <div >
        <p style={styles.label}>{label}</p>
        <Lottie
          options={option}
          height={400}
          width={400}
          isClickToPauseDisabled={true}
        />

      </div>)
}


const styles = {
    label:{
        textAlign:"center",
        marginBottom:0,
        fontFamily: "Figtree",
        color: "#fff",
        fontSize: 18
    }
}