export default async function authenticateMediatore(username, nu_documento, token){

    let url = `${process.env.REACT_APP_AI_API_URL}/api/v1/user/intermediate/link/`
    let response = await fetch(url, {
        method: "POST",
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          usuario: username,
          nu_documento: nu_documento
        })
      })
    return response
}

