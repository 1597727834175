import {
  Button,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles"

export default function ValidationQuestionsDialog({
  qaData,
  openQuestionsDialog,
  selectedAnswers,
  handleAnswerChange,
  handleValidateAnswers
}) {

  const styles = useStyles()

  return (
    <Dialog open={openQuestionsDialog}>
      <DialogTitle>Preguntas para validar afiliación</DialogTitle>
      <DialogContent>
        {qaData?.map((question) => (
          <div key={question.codPreguntas}>
            {question.codPreguntas === 1 && (
              <h2>¿Cuál de estos teléfonos reconoces?</h2>
            )}
            {question.codPreguntas === 2 && (
              <h2>¿Cuál es tu fecha de nacimiento?</h2>
            )}
            {question.codPreguntas === 3 && (
              <h2>¿Cuál fue tu último empleador?</h2>
            )}
            {question.respuestas.map((answer, index) => (
              <div key={index}>
                <Radio
                  checked={
                    selectedAnswers[question.codPreguntas] === answer.valor
                  }
                  onChange={() =>
                    handleAnswerChange(question.codPreguntas, answer.valor)
                  }
                />
                {answer.valor}
              </div>
            ))}
          </div>
        ))}
      </DialogContent>

      <DialogActions>
        <Button className={styles.button} onClick={() => handleValidateAnswers()}>Validar</Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  button: {
    color: "#FFFFFF",
    backgroundColor: "#8066EC"
  }
})
