const options = [
  {
    option: "¿Cómo utilizar el micrófono?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/%C2%BFC%C3%B3mo%20utilizar%20el%20micr%C3%B3fono_%20-%20Made%20with%20Clipchamp%20(2)%20(1).mp4",
  },
  {
    option:
      "¿Cómo consultar mi deuda pendiente de seguro de automóvil con Seguros Crecer?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Funcionalidad%20de%20poliza%20pendiente%20de%20pago%20-%20Made%20with%20Clipchamp%20(1).mp4",
  },
  {
    option:
      "¿Cómo consultar mi estatus de reclamo de seguro de automóvil en Seguros Crecer?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Funcionalidad%20de%20estatus%20de%20siniestro%20-%20Made%20with%20Clipchamp.mp4",
  },
  {
    option: "¿Cómo cotizar un seguro de automóvil en Seguros Crecer?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Funcionalidad%20de%20cotizaci%C3%B3n%20-%20Made%20with%20Clipchamp.mp4",
  },
  {
    option: "¿Cómo consultar el balance de mi fondo de pensión en AFP Crecer?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Funcionalidad%20de%20balance%20de%20fondo%20de%20pensiones%20-%20Made%20with%20Clipchamp%20(1).mp4",
  },
  {
    option: "¿Cómo vincularme como empleado de Seguros Crecer?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Vinculacion%20empleados%20seguros%20crecer%20-%20Made%20with%20Clipchamp%20(1).mp4",
  },
  {
    option: "¿Cómo vincularme como asegurado de Seguros Crecer?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Vinculacion%20a%20Seguros%20Crecer.mp4",
  },
  {
    option: "¿Cómo vincularme como afiliado de AFP Crecer?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Vinculacion%20a%20AFP%20Crecer.mp4",
  },
  {
    option: "¿Cómo saber a cuáles organizaciones estoy vinculado?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/%C2%BFA%20cu%C3%A1les%20orgnizaciones%20estoy%20vinculado_%20-%20Made%20with%20Clipchamp%20(1).mp4",
  },
];

export default options;
