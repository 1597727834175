import { useState } from 'react'

const API_URL = process.env.REACT_APP_AI_API_URL + '/api/v1/user/'
const API_UPDATE = process.env.REACT_APP_AI_API_URL + '/api/v1/user-update/'
const DATA = {
  error: false,
  success: false,
  user_info: null
}

/**
 * Get user info
 */
export default function UseGetUserInfo() {
  const [data, setData] = useState(DATA)

  const getUserInfo = async (auth_token) => {
    const requestInfo = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth_token
      },
      body: JSON.stringify()
    };
    setData({ ...DATA, isLoading: true })
    await fetch(`${API_URL}`, requestInfo)
      .then(res => res.json())
      .then(result => {
        if (result.success) {
          setData({
            user_info: result.user_info,
            success: true,
          })
        } else {
          setData({
            error: result.error
          })
        }
      })
      .catch(error => {

        setData(c => ({ ...c, error }))
      })
  }
  const updateUserInfo = async (auth_token, user_info) => {
    const requestInfo = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth_token
      },
      body: JSON.stringify(user_info)
    };
    setData({ ...DATA, isLoading: true })
    await fetch(`${API_UPDATE}`, requestInfo)
      .then(res => res.json())
      .then(result => {
        if (result.success) {
          setData({
            success: true,
          })
        } else {
          setData({
            error: result.error
          })
        }
      })
      .catch(error => {
        setData(c => ({ ...c, error }))
      })
  }
  return { ...data, getUserInfo, updateUserInfo }
}
