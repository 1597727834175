const Intro = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Intro_Afiliados_1.0.mp4'
const IntroVisitantes = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Intro_visitantes.mp4'
var IntroAfiliados = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Intro_Afiliados_1.0.mp4'
const Inversiones = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta%2FReporte%20inversiones%20Enero%202023_x264.mp4'
const SeleccionaFecha = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Intro_preguntas_seguridad_1.3.mp4'
const RequerirDatos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/003%20v.%202.2%20-%20Consulta%20Balance_x264.mp4'
const BeneficiosPensiones = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Quiero_conocer_mis_beneficios_Ver.19_03_2022_x264.mp4'
const PensionPorVejez = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Pension_por_vejez_1.0.mp4'
const RegresarPensionPorVejez = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Regresar_de_Pensin_por_Vejez_Ver_0.mp4'
const PensionPorSobrevivencia = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Pensin_de_Sobrevivencia_Ver_0.mp4'
const RegresarPensionPorSobrevivencia = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Regresar_de_Pensin_de_Sobrevivencia_Ver.mp4'
const PensionPorDiscapacidad = 'https://prototipos.nyc3.digitaloceanspaces.com/video-consulta/Pensin%20por%20Discapacidad%20-%20Ver.%202.3_x264.mp4'
const RegresarPensionPorDiscapacidad = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Regresar_de_Pensin_por_Discapa_cidad_Ver.0.mp4'
const CesantiaPorEdadAvanzada = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Cesantia_por_edad_avanzada_1_0.mp4'
const RegresarCesantiaPorEdadAvanzada = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Regresar_de_Cesanta_por_Edad_Avanzada_Ver_0.mp4'
const EnfermedadTermnialIngresoTardío = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Enfermedad%20Terminal%20o%20Ingreso%20Tardo%2027_06_2022_x264.mp4'
const RegresarEnfermedadTermnialIngresoTardío = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Regresar_Enfermedad_Terminal_o_Ingreso_Tardo%20_Ver_0.mp4'
const QuieroConocerMas = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Conocer_mas_de_AFP_Crecer.mp4'
const QuieroAfiliarme = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Quiero_afiliarme.mp4'
const RegresarBeneficiosPensiones = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Regresar%20de%20Conocer%20mis%20beneficios_x264.mp4'
const EnviarDatosAfiliacion = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Respuesta%20luego%20de%20ingresar%20datos%20en%20formulario%20afiliacion_x264.mp4'
const IntroCesarIglesias ='https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Intro%20d_x264.mp4'
const Persmiso ='https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/2.%20Por%20qu%20no%20me%20pidieron%20permiso%20para%20usar%20mi%20dinero%20en%20esto_%20b%2020s_x264.mp4'
const Consultarme = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/3.%20Pueden%20invertir%20mi%20dinero%20sin%20consultarme_%2020s_x264.mp4'
const Inversion = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/4.%20Qu%20tan%20buena%20es%20esta%20inversin_%20b_x264.mp4'
const Compra = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/5.%20Por%20qu%20no%20me%20contaron%20antes%20de%20esta%20compra__x264.mp4'
const EsSeguro = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/6.%20Es%20seguro%20poner%20mi%20dinero%20aqu__x264.mp4'
const EsPermitido = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/7.%20La%20ley%20les%20permite%20hacer%20estas%20inversiones__x264.mp4'
const QueGanoConLaCompra = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/1.%20Qu%20gano%20yo%20con%20esto_%2020s_x264.mp4'
const SoyDueno = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/9.%20Con%20mi%20ahorro,%20yo%20soy%20dueo%20de%20una%20parte%20de%20esas%20acciones__x264.mp4'
const Riesgos ='https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/10.%20Cules%20son%20los%20riesgos%20de%20poner%20el%20dinero%20en%20empresas__x264.mp4'
const SiGanan = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/11.%20Y%20si%20ganan%20dinero,%20me%20van%20a%20informar__x264.mp4'
const PorQuePueden = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/12.%20Por%20qu%20pueden%20comprar%20acciones%20pero%20no%20me%20devuelven%20parte%20de%20mi%20dinero__x264.mp4'
const PorQueInvierten = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/13.%20%20Por%20qu%20solo%203%20AFPs%20invirtieron%20y%20las%20dems%20no__x264.mp4'
const SiQuiebra = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/8.%20Y%20si%20la%20empresa%20quiebra,%20qu%20pasa%20con%20mi%20dinero__x264.mp4'

export const nodes = [
    {
        id: 0,
        title: '¿Eres afiliado o visitante?',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 4,
                videoUrl: IntroAfiliados,
                text: 'Soy afiliado de AFP Crecer',
                keyword: 'afiliado'
            },
            {
                type: 'node',
                videoUrl: IntroVisitantes,
                text: 'Soy visitante',
                keyword: 'visitante',
                nextNodeId: 2,
            }
        ],
        video: Intro,
    },

    {
        id: 1,
        title: 'Digita tus datos:',
        inputs: [
            {
                type: 'number',
                text: 'No. de Cédula de Identidad:',
                content: 'Id'


            },
            {
                type: 'number',
                text: 'No. de Teléfono Celular:',
                content: 'CellPhone'

            },
        ],
        buttons: [
            {
                type: 'send',
                videoUrl: SeleccionaFecha,
                text: 'Enviar',
                nextNodeId: 6,
                keyword: 'enviar'
            },
            {
                type: 'node',
                videoUrl: IntroAfiliados,
                text: 'Regresar',
                nextNodeId: 4,
                keyword: 'regresar'
            }
        ],

    },
    {
        id: 2,
        title: '¿Cómo te puedo ayudar?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: QuieroConocerMas,
                text: 'Quiero saber más de AFP Crecer',
                nextNodeId: 3,
                keyword: 'saber'
            },
            {
                type: 'node',
                videoUrl: BeneficiosPensiones,
                text: 'Quiero conocer sus beneficios',
                nextNodeId: 13,
                keyword: 'beneficios'
            },
            {
                type: 'node',
                videoUrl: QuieroAfiliarme,
                text: 'Quiero afiliarme a AFP Crecer',
                nextNodeId: 20,
                keyword: 'afiliarme'
            },
        ],
    },
    {
        id: 3,
        title: '¿Cómo te puedo ayudar?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: BeneficiosPensiones,
                text: 'Quiero conocer sus beneficios',
                nextNodeId: 13,
                keyword: 'beneficios'
            },
            {
                type: 'node',
                videoUrl: QuieroAfiliarme,
                text: 'Quiero afiliarme a AFP Crecer',
                nextNodeId: 20,
                keyword: 'afiliarme'
            },
            {
                type: 'node',
                videoUrl: IntroVisitantes,
                text: 'Regresar',
                nextNodeId: 2,
                keyword: 'regresar'
            },
        ],
    },
    {
        id: 4,
        title: '¿Cómo te puedo ayudar?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: BeneficiosPensiones,
                text: 'Quiero conocer mis beneficios',
                nextNodeId: 6,
                keyword: 'beneficios'
            },
            {
                type: 'node',
                videoUrl: Inversiones,
                text: 'Quiero un reporte de mis inversiones',
                nextNodeId: 12,
                keyword: 'inversiones'
            },
            {
                type: 'node',
                videoUrl: RequerirDatos,
                text: 'Quiero un resumen de mi Estado de Cuenta ',
                nextNodeId: 1,
                keyword: 'cuenta'
            }

        ],
    },
    {
        id: 5,
        title: '',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: IntroAfiliados,
                text: 'Regresar',
                nextNodeId: 4,
                keyword: 'regresar'
            },
        ],
    },
    {
        id: 6,
        title: '¿Sobre cuál quieres conocer más?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: PensionPorVejez,
                text: 'Pensión por Vejez',
                nextNodeId: 7,
                keyword: 'vejez'
            },
            {
                type: 'node',
                videoUrl: PensionPorSobrevivencia,
                text: 'Pensión de Sobrevivencia',
                nextNodeId: 8,
                keyword: 'sobrevivencia'
            },
            {
                type: 'node',
                videoUrl: PensionPorDiscapacidad,
                text: 'Pensión por Discapacidad',
                nextNodeId: 9,
                keyword: 'discapacidad'
            },
            {
                type: 'node',
                videoUrl: CesantiaPorEdadAvanzada,
                text: 'Cesantía por Edad Avanzada',
                nextNodeId: 10,
                keyword: 'edad'
            },
            {
                type: 'node',
                videoUrl: EnfermedadTermnialIngresoTardío,
                text: 'Enfermedad Terminal o Ingreso Tardío',
                nextNodeId: 11,
                keyword: 'terminal'
            },
            {
                type: 'node',
                videoUrl: RegresarBeneficiosPensiones,
                text: 'Volver al inicio',
                nextNodeId: 4,
                keyword: 'volver'
            },

        ],
    },
    {
        id: 7,
        title: '¿Qué quieres hacer?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: RegresarPensionPorVejez,
                text: 'Conocer otros beneficios de pensiones',
                nextNodeId: 6,
                keyword: 'otros'
            },
            {
                type: 'node',
                videoUrl: RegresarBeneficiosPensiones,
                text: 'Volver al inicio',
                nextNodeId: 4,
                keyword: 'volver'
            },
        ],
    },
    {
        id: 8,
        title: '¿Qué quieres hacer?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: RegresarPensionPorSobrevivencia,
                text: 'Conocer otros beneficios de pensiones',
                nextNodeId: 6,
                keyword: 'otros'
            },
            {
                type: 'node',
                videoUrl: RegresarBeneficiosPensiones,
                text: 'Volver al inicio',
                nextNodeId: 4,
                keyword: 'volver'
            },
        ],
    },
    {
        id: 9,
        title: '¿Qué quieres hacer?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: RegresarPensionPorDiscapacidad,
                text: 'Conocer otros beneficios de pensiones',
                nextNodeId: 6,
                keyword: 'otros'
            },
            {
                type: 'node',
                videoUrl: RegresarBeneficiosPensiones,
                text: 'Volver al inicio',
                nextNodeId: 4,
                keyword: 'volver'
            },
        ],
    },
    {
        id: 10,
        title: '¿Qué quieres hacer?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: RegresarCesantiaPorEdadAvanzada,
                text: 'Conocer otros beneficios de pensiones',
                nextNodeId: 6,
                keyword: 'otros'
            },
            {
                type: 'node',
                videoUrl: RegresarBeneficiosPensiones,
                text: 'Volver al inicio',
                nextNodeId: 4,
                keyword: 'volver'
            },
        ],
    },
    {
        id: 11,
        title: '¿Qué quieres hacer?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: RegresarEnfermedadTermnialIngresoTardío,
                text: 'Conocer otros beneficios de pensiones',
                nextNodeId: 6,
                keyword: 'otros'
            },
            {
                type: 'node',
                videoUrl: RegresarBeneficiosPensiones,
                text: 'Volver al inicio',
                nextNodeId: 4,
                keyword: 'volver'
            },
        ],
    },
    {
        id: 12,
        title: '¿Qué quieres hacer?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: IntroAfiliados,
                text: 'Regresar',
                nextNodeId: 4,
                keyword: 'regresar'
            },
        ],
    },
    {
        id: 13,
        title: '¿Sobre cuál quieres conocer?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: PensionPorVejez,
                text: 'Pensión por Vejez',
                nextNodeId: 14,
                keyword: 'vejez'
            },
            {
                type: 'node',
                videoUrl: PensionPorSobrevivencia,
                text: 'Pensión de Sobrevivencia',
                nextNodeId: 15,
                keyword: 'Sobrevivencia'
            },
            {
                type: 'node',
                videoUrl: PensionPorDiscapacidad,
                text: 'Pensión por Discapacidad',
                nextNodeId: 16,
                keyword: 'discapacidad'
            },
            {
                type: 'node',
                videoUrl: CesantiaPorEdadAvanzada,
                text: 'Cesantía por Edad Avanzada',
                nextNodeId: 17,
                keyword: 'edad'
            },
            {
                type: 'node',
                videoUrl: EnfermedadTermnialIngresoTardío,
                text: 'Enfermedad Terminal o Ingreso Tardío',
                nextNodeId: 18,
                keyword: 'terminal'
            },
            {
                type: 'node',
                videoUrl: RegresarBeneficiosPensiones,
                text: 'Volver al inicio',
                nextNodeId: 2,
                keyword: 'volver'
            },

        ],
    },
    {
        id: 14,
        title: '¿Qué quieres hacer?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: RegresarPensionPorVejez,
                text: 'Conocer otros beneficios de pensiones',
                nextNodeId: 13,
                keyword: 'beneficios'
            },
            {
                type: 'node',
                videoUrl: RegresarBeneficiosPensiones,
                text: 'Volver al inicio',
                nextNodeId: 2,
                keyword: 'volver'
            },
        ],
    },
    {
        id: 15,
        title: '¿Qué quieres hacer?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: RegresarPensionPorSobrevivencia,
                text: 'Conocer otros beneficios de pensiones',
                nextNodeId: 13,
                keyword: 'beneficios'
            },
            {
                type: 'node',
                videoUrl: RegresarBeneficiosPensiones,
                text: 'Volver al inicio',
                nextNodeId: 2,
                keyword: 'volver'
            },
        ],
    },
    {
        id: 16,
        title: '¿Qué quieres hacer?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: RegresarPensionPorDiscapacidad,
                text: 'Conocer otros beneficios de pensiones',
                nextNodeId: 13,
                keyword: 'beneficios'
            },
            {
                type: 'node',
                videoUrl: RegresarBeneficiosPensiones,
                text: 'Volver al inicio',
                nextNodeId: 2,
                keyword: 'volver'
            },
        ],
    },
    {
        id: 17,
        title: '¿Qué quieres hacer?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: RegresarCesantiaPorEdadAvanzada,
                text: 'Conocer otros beneficios de pensiones',
                nextNodeId: 13,
                keyword: 'otros'
            },
            {
                type: 'node',
                videoUrl: RegresarBeneficiosPensiones,
                text: 'Volver al inicio',
                nextNodeId: 2,
                keyword: 'volver'
            },
        ],
    },
    {
        id: 18,
        title: '¿Qué quieres hacer?',
        inputs: [
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: RegresarEnfermedadTermnialIngresoTardío,
                text: 'Conocer otros beneficios de pensiones',
                nextNodeId: 13,
                keyword: 'beneficios'
            },
            {
                type: 'node',
                videoUrl: RegresarBeneficiosPensiones,
                text: 'Volver al inicio',
                nextNodeId: 2,
                keyword: 'volver'
            },
        ],
    },
    {
        id: 19,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: IntroAfiliados,
                text: "Continuar",
                correct: 1,
                nextNodeId: 4,
                keyword: 'continuar'
            }
        ],

    },
    {
        id: 20,
        title: 'Digita tus datos:',
        inputs: [
            {
                type: 'text',
                text: 'Nombre:',
                content: 'Name'

            },
            {
                type: 'number',
                text: 'No. de Teléfono Celular:',
                content: 'CellPhone'

            }, {
                type: 'number',
                text: 'No. de Cédula de Identidad:',
                content: 'Id'
            }
        ],
        buttons: [
            {
                type: 'refer',
                videoUrl: EnviarDatosAfiliacion,
                text: 'Enviar',
                nextNodeId: 2,
                keyword: 'enviar'
            },
            {
                type: 'node',
                videoUrl: IntroVisitantes,
                text: 'Regresar',
                nextNodeId: 2,
                keyword: 'regresar'
            }
        ],

    },
    {
        id: 21,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: Inversiones,
                text: "Continuar",
                correct: 1,
                nextNodeId: 12,
                keyword: 'continuar'
            },
            {
                type: 'node',
                videoUrl: IntroAfiliados,
                text: "Regresar",
                correct: 1,
                nextNodeId: 4,
                keyword: 'regresar'
            }
        ],

    },
    {
        id: 22,
        title: 'Digita tus datos:',
        inputs: [
            {
                type: 'text',
                text: 'Nombre:',
                content: 'Name'

            },
            {
                type: 'number',
                text: 'No. de Teléfono Celular:',
                content: 'CellPhone'

            }, {
                type: 'number',
                text: 'No. de Cédula de Identidad:',
                content: 'Id'
            }
        ],
        buttons: [
            {
                type: 'refer',
                videoUrl: IntroAfiliados,
                text: 'Enviar',
                nextNodeId: 4,
                keyword: 'enviar'
            },
            {
                type: 'node',
                videoUrl: IntroAfiliados,
                text: 'Regresar',
                nextNodeId: 4,
                keyword: 'regresar'
            }
        ],

    },
    {
        id: 23,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: IntroAfiliados,
                text: "Continuar",
                correct: 1,
                nextNodeId: 4,
                keyword: 'continuar'
            },
            {
                type: 'close',
                videoUrl: IntroAfiliados,
                text: "Finalizar",
                correct: 1,
                nextNodeId: 4,
                keyword: 'finalizar'
            }
        ],

    },
    {
        id: 24,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                num:1,
                videoUrl: QueGanoConLaCompra,
                text: "¿Qué gano yo con la compra de estas acciones?",
                nextNodeId: 25,
            },
        ],
        video:  IntroCesarIglesias,

    },
    {
        id: 25,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                num:2,
                videoUrl: Persmiso,
                text: "¿Por qué no me pidieron permiso para esto?",
                nextNodeId: 26,
            },
        ],
    },
    {
        id: 26,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                num:3,
                videoUrl: Consultarme,
                text: "¿Pueden invertir mi dinero sin consultarme?",
                nextNodeId: 27,
            },
        ],

    },
    {
        id: 27,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                num:4,
                videoUrl: Inversion,
                text: "¿Qué tan buena es esta inversión?",
                nextNodeId: 28,
            },
        ],

    },
    {
        id: 28,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                num:5,
                videoUrl: Compra,
                text: "¿Por qué no me contaron antes de esta compra?",
                nextNodeId: 29,
            },
        ],

    },
    {
        id: 29,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                num:6,
                videoUrl: EsSeguro,
                text: "¿Es seguro poner mi dinero aquí?",
                nextNodeId: 30,
            },
        ],

    },
    {
        id: 30,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                num:7,
                videoUrl: EsPermitido,
                text: "¿Está permitido invertir mi dinero así?",
                nextNodeId: 31,
            },
        ],

    },
    {
        id: 31,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                num:8,
                videoUrl: SiQuiebra,
                text: "¿Si la empresa quiebra, qué pasa con mi dinero?",
                nextNodeId: 32,
            },
        ],

    },
    {
        id: 32,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                num:9,
                videoUrl: SoyDueno,
                text: "¿Ahora soy dueño de un pedacito de esa empresa?",
                nextNodeId: 33,
            },
        ],

    },
    {
        id: 33,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                num:10,
                videoUrl: Riesgos,
                text: "¿Cuál es el riesgo de invertir en empresas?",
                nextNodeId: 34,
            },
        ],

    },
    {
        id: 34,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                num:11,
                videoUrl: SiGanan,
                text: "¿Y si ganan dinero, me van a informar?",
                nextNodeId: 35,
            },
        ],

    },
    {
        id: 35,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                num:12,
                videoUrl: PorQuePueden,
                text: "¿Por qué no mejor me devuelven mi dinero?",
                nextNodeId: 36,
            },
        ],

    },
    {
        id: 36,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                num:13,
                videoUrl: PorQueInvierten,
                text: "¿Por qué algunas AFP invirtieron y otras no?",
                nextNodeId: 37,
            },
        ],

    },
    {
        id: 37,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                num:13,
                videoUrl: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Cierre_x264.mp4',
                text: "Continuar",
                nextNodeId: 38,
            },
        ],

    },
    {
        id: 38,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: IntroCesarIglesias,
                text: "Volver al inicio",
                nextNodeId: 24,
            },
            {
                type: 'link',
                videoUrl: Intro,
                url:'https://lisa.do',
                text: "Seguir conversando con Lisa",
                nextNodeId: 0,
            }
        ],

    }
];