import { useState } from 'react';
import axios from 'axios';

const API_VALIDATE = process.env.REACT_APP_API_URL + '/api/user/validateuser'
const API_AUTH = process.env.REACT_APP_API_URL + '/api/user/authenticate/'
const API_UPDATE = process.env.REACT_APP_AI_API_URL + '/api/v1/user-update/'
const DATA = {
  error: false,
  success: false,
  user_info: null
}

export default function useAffiliateUser() {
    const [data, setData] = useState(DATA);
    const [openResultsDialog, setOpenResultsDialog] = useState(false);

    const getAffiliateData = async (cedula) => {
        setData({ ...DATA, isLoading: true })
        var content = {
            IssuedId: cedula
        }

        await axios.post(API_AUTH, content)
            .then(function (response) {
                if (!response.data.successed) {
                    setData({ 
                        ...DATA, 
                        resultText: 'Esta cédula no está registrada en nuestra base de datos.',
                        isLoading: false,
                        openQuestionsDialog: false,
                    })
                    setOpenResultsDialog(true)
                } else {
                    setData({ 
                        ...DATA, 
                        questionsAnswersData: response.data.value,
                        openQuestionsDialog: true 
                    })
                }
            })
        
    };

    const validateAnswers = async (cedula, answerValues) => {
        setData({ ...DATA, isLoading: true })
        var content = {
            IssuedId: cedula,
            SecurityQuestions: answerValues
        }
        await axios.post(API_VALIDATE, content)
            .then(function (response) {
                setData({
                    ...DATA,
                    resultText: 'Respuestas respondidas correctamente, ha sido vinculado correctamente.',
                    isLoading: false,
                    openQuestionsDialog: false,
                    switchAffiliateStatus: true,
                })
                setOpenResultsDialog(true)
            })
            .catch(function (error) {
                setData({ 
                    ...DATA, 
                    resultText: 'Sus respuestas no coinciden.',
                    isLoading: false,
                    openQuestionsDialog: false,
                })
                setOpenResultsDialog(true)
            });
    };

    return {...data, getAffiliateData, validateAnswers, openResultsDialog, setOpenResultsDialog}
}
