import axios from "axios";

export default async function getUserOrganizations(token) {
  const url = `${process.env.REACT_APP_AI_API_URL}/api/v1/get_user_organizations/`;

  try {
    const response = await axios({
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    });
    return { data: response.data, error: null, response };
  } catch (e) {
    const error = e.response
      ? `${e.response.status} ${e.response.statusText}`
      : e.message;
    return { data: null, error, response: e.response };
  }
}
