/* eslint-disable react-hooks/exhaustive-deps */
import '@fontsource/roboto/500.css'
import '../css/login.css'
import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import { Stack } from '@mui/material'
import { Button } from '@mui/material'
import { Alert, Backdrop, CircularProgress } from '@mui/material'
import { SignUpForm } from './SignUpForm'
import { ArrowBack, VisibilityOff, Visibility } from '@mui/icons-material'
import Authenticate from '../hooks/authenticate'
import { FormControlLabel } from '@mui/material'
import { FormControl, InputLabel, OutlinedInput, Chip } from '@mui/material'
import { Checkbox, InputAdornment, IconButton, Card } from '@mui/material'
import Logo from '../media/Morado_Lisa_Logo.png'
import Cookies from 'universal-cookie'
import PasswordReset from './PasswordReset'
import { loginGoogle } from '../hooks/useGoogleSSO'
import GoogleSSOButton from "../components/GoogleSSOButton"
import { deleteSessionValue, setSessionValue } from "../services/organizations/SessionManager"


const DATE = new Date()
const COOKIES_EXPIRE = new Date('12-01-' + (DATE.getFullYear() + 1))
export function UserLogin({ setToken, setId, backButton, setState, skipPreScreening, backLogin }) {

    const [loading, setLoading] = useState(false)
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState()
    const [alert, setAlert] = useState()
    const [register, setRegister] = useState()
    const [keepLogin, setKeepLogin] = useState(false)
    const [passwordReset, setPasswordReset] = useState()
    const [message, setMessage] = useState()
    const [displayMessage, setDisplayMessage] = useState()
    const [onboard, setOnboard] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [loginPage, setLoginPage] = useState(true);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const DOMAIN = process.env.REACT_APP_DOMAIN;
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickKeepLogin = (event) => {
        console.log(event.target.checked)
        setKeepLogin(event.target.checked)
    }

    const {
        error,
        isLoading,
        isAuthenticated,
        success,
        id,
        token,
        authenticateUser,
    } = Authenticate()
    

    useEffect(() => {
        if (isAuthenticated) {
            deleteSessionValue("token")
            setToken(token)
            setId(id)
            setState({ hasLogin: true })
            setState({ goLogin: false })
    
            // Save the token only if the "keepLogin" checkbox is checked
            if (keepLogin) {
                console.log("Saving token for persistent login");
                setSessionValue("token", token);
            }
        } else if (success) {
            displayAlert('Usuario o Contraseña Incorrectos', 'error')
        }
    
        if (error) {
            displayAlert(error, 'error')
        }
    
        if (message) {
            setDisplayMessage(true)
        }
    
        if (skipPreScreening) {
            setOnboard(false)
        }
    
    }, [isAuthenticated, success, token, passwordReset, message, error, keepLogin, setToken, setId, setState, skipPreScreening]);
    

    if (register) {
        return (
            <SignUpForm setRegister={setRegister} setMessage={setMessage} />)
    }

    if (passwordReset) {
        return (
            <React.Fragment>
                <Backdrop
                    sx={{ color: '#fff', zIndex: 500 }}
                    open={loading}
                >
                    <CircularProgress sx={{ color: "white", fontSize: 80 }} />
                </Backdrop>
                {alert}
                <Button className="back-btn" variant="contained" onClick={() => { setPasswordReset(false); setLoginPage(true); }}> <ArrowBack /> Regresar</Button>
                <PasswordReset closeView={setPasswordReset} displayAlert={displayAlert} setLoading={setLoading} />
            </React.Fragment>
        )

    }

    function backBtn() {
        setState({ hasLogin: false })
        setState({ conversational: false })
        setState({ goLogin: false })
    }

    function handleDisplayMessage() {
        setDisplayMessage(false)
        setMessage(false)
    }

    function handleSubmit(event) {
        event.preventDefault();
        login();
    }

    const card = <div style={{ padding: '6%' }}>
        <h3>¡Registro exitoso!</h3>
        <div style={{ marginTop: '20px', maxHeight: '38vh', overflow: 'scroll' }}>
            <p>{message}</p>
        </div>
        <Button style={{ marginTop: '20px' }} onClick={() => handleDisplayMessage()}>Aceptar</Button>
    </div>


    if (onboard) {
        return (
            <React.Fragment>
                <Button className="back-btn" variant="contained" onClick={() => { setPasswordReset(false); setLoginPage(true); }}> <ArrowBack /> Regresar</Button>                <Stack className="form-login" spacing={2}>
                    <div style={{ alignItems: 'center', textAlign: 'center' }}>
                        <h1 style={{ 'color': 'white', fontSize: '5em', marginBottom: '-20px' }}><b>LISA</b></h1>
                        <p>Respuestas Precisas</p><br /><br />

                        <p><b>Una experiencia</b> <br />
                            Simple, humana y personalizada<br /><br /><br />
                        </p>

                        <p>
                            Gracias por tu interés en participar en las pruebas de la nueva versión de Lisa 2.0.<br /><br />
                            Para empezar a probar, deberás registrarte y luego esperar a recibir un mensaje de acceso.
                        </p><br /><br />
                    </div>
                    <Button className="login-btn" variant="contained" onClick={() => setRegister(true)} >Registrarme</Button>
                    <Button className="register-btn" variant="contained" onClick={() => setOnboard(false)}>Ya tengo una cuenta </Button>

                </Stack>
            </React.Fragment>
        )
    }

    if (loginPage) {
        return (
            <React.Fragment>
                <form onSubmit={handleSubmit}>
                    <Backdrop
                        style={{ zIndex: 10 }}
                        open={displayMessage}
                    >
                        <Chip className="close-terms" label={<span>X</span>} variant="outlined" onClick={() => handleDisplayMessage()} style={{ zIndex: 10000 }}> </Chip>
                        <Card className='terms' style={{ marginTop: '30%', maxHeight: '65%', width: '90%' }}
                            variant="outlined">
                            {card}
                        </Card>
                    </Backdrop>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: 500 }}
                        open={isLoading}
                    >
                        <CircularProgress sx={{ color: "white", fontSize: 80 }} />
                    </Backdrop>
                    {alert}
                    <Stack className="form-login" spacing={2}>
                        <img src={Logo} className='form-logo' alt='AFP crecer'
                            style={{
                                position: 'relative',
                                maxWidth: '200px',
                                zIndex: 11,
                                margin: '0 auto'
                            }}></img>
                        <p className='app-title'>Inicia sesión en tu cuenta de Lisa</p>
                        <InputLabel>Número de teléfono:</InputLabel>
                        <FormControl sx={{ m: 1 }} variant="outlined" className='phone-number'>
                            <TextField value={phone} onChange={e => validatePhone(e)} autoComplete='off' />
                        </FormControl>
                        <InputLabel htmlFor="outlined-adornment-password" className='pass-label'>Contraseña:</InputLabel>
                        <FormControl sx={{ m: 1 }} variant="outlined" className='password-field custom-border'>
                            <OutlinedInput
                                onFocus={function (e) {
                                    var val = e.target.value;
                                    e.target.value = '';
                                    e.target.value = val;
                                }}
                                onChange={e => setPassword(e.target.value)}
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete='off'
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={(event) => {
                                                event.preventDefault();
                                            }}
                                            onMouseUp={(event) => {
                                                event.preventDefault();
                                            }}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <div className='forgot-password'>
                            <p>No puedo acceder, <span onClick={() => setPasswordReset(true)} style={{ cursor: 'pointer', fontStyle: 'italic' }}>olvidé mi contraseña</span></p>
                        </div>
                        <FormControlLabel
                            className='keep-login'
                            control={
                                <Checkbox className='check'
                                    checked={keepLogin}
                                    onChange={handleClickKeepLogin} />}
                            label="Mantener la sesión iniciada en este dispositivo" />
                        <Button className="login-btn" variant="contained" type="submit" autoFocus tabIndex={-1}>Acceder</Button>
                        <div style={{ margin: '0 auto' }}>
                            <p style={{ marginBottom: '0', marginTop: '1rem' }}>También puedes:</p>
                        </div>

                        <GoogleSSOButton loginGoogle={loginGoogle} />

                        <div style={{ margin: '0 auto' }}>
                            <p style={{ marginBottom: '0', marginTop: '2rem' }}>¿Aún no tienes una cuenta?</p>
                        </div>
                        <a style={{ margin: '0 auto' }} className="btn-tertiary" variant="contained" onClick={() => setRegister(true)}>Registrate aquí</a>

                    </Stack>
                </form>
            </React.Fragment>
        )
    }

    function login() {
        // validate phone and password are not empty
        if (!phone || !password) {
            displayAlert('Por favor ingrese su usuario y contraseña', 'error')
            return
        }
        authenticateUser(phone, password)
    }

    function back() {
        backButton()
    }

    function validatePhone(event) {
        const { value } = event.target;
        // Allow only digits and ensure the length is exactly 10
        if (/^\d{0,10}$/.test(value)) {
            setPhone(value);
        } else {
            event.preventDefault();
        }
    }
    

    function displayAlert(message, type) {
        if (type === 'error') {

            setAlert(<Alert className='alert' severity="error">{message}</Alert>);
            setTimeout(() => {
                setAlert()
            }, 3000)

        } else {

            setAlert(<Alert className='alert' severity="success">{message}</Alert>);
            setTimeout(() => {
                setAlert()
            }, 3000)

        }

    }

}



