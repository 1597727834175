import axios from "axios";
import { exception } from "react-ga";

class UnibeAPIClient {
  constructor(token) {
    this.headers = {
      "Content-Type": "application/json",
      "x-access-token": token,
    };
  }

  async authenticate(crendentials) {
    let data = null;
    let error = null;
    let response = null;

    let url = `${process.env.REACT_APP_AI_API_URL}/api/v1/authenticate-unibe/`

    try {
        response = await axios.post(url,crendentials,{
          headers: this.headers
        });

        if (response.status === 200)
        {
            data = response.data
        }
    }catch(e){
        error = e
    }
    
    return {
        response, error, data
    }
  }
}

export default UnibeAPIClient;
