import axios from "axios"

const getOrganizationProfiles = async (token) => {
    let url = `${process.env.REACT_APP_AI_API_URL}/api/v1/users/organizations/profiles/`

    let headers = {
        'Content-Type': 'application/json',
        'x-access-token': token
    }

    let response = await axios.get(url,{
        headers: headers
    })
    return response.data

}

export default getOrganizationProfiles