import { useState } from "react";

const URL_ADD_PHONE_NUMBER =  `${process.env.REACT_APP_AI_API_URL}/api/v1/add-phone-number/`

export default function useAddPhoneNumber(){
    const [data, setData] = useState({"success": false})
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    const requestSavePhoneNumber = async (token, phone) => {
        const requestInfo = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-access-token': token
            },
            body: JSON.stringify({
                "phone": phone
            })
        };
        setIsLoading(true)
        let response = await fetch(URL_ADD_PHONE_NUMBER, requestInfo )
        let response_json = await response.json()
        if(response.ok){
            setData(response_json)
        }else{
            setError(response)
        }

        setIsLoading(false)
        return response_json
    }


    return {data, error, isLoading, requestSavePhoneNumber}
}