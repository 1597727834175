import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

function MsCallbackHandle ({code}) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        
        // Send a request to the backend with the code
        axios.get(`${process.env.REACT_APP_AI_API_URL}/api/v1/microsoft/callback?code=${code}`)
            .then(response => {
                window.location.href = response.data.url;
            })
            .catch(error => {
                // Handle any errors that occurred during the request
                window.location.href = '/';
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <div>
            <h1>Vinculando a la organizacion</h1>
            {loading ? (
                <CircularProgress />
            ) : (
                <p></p>
            )}
        </div>
    );
};

export default MsCallbackHandle;