export const getUserEmployeeLink = async (token, email) => {
  let response_api = await fetch(
    process.env.REACT_APP_MICROSOFT_LOGIN_API +
      `?token=${token}&email=${email?.toLowerCase()}`,
    {
      method: "GET",
    }
  );
  let response_url = await response_api.json()
  return response_url.url
};
