

export const shortTerms = <div>
    Ten en cuenta que Lisa Beta 2.0 es un experimento<br></br><br></br>

    Mientras utilizas Lisa Beta 2.0, no olvides que:<br></br><br></br>

    Lisa Beta 2.0 no siempre dará respuestas correctas.<br></br><br></br>

    Lisa Beta 2.0 podría proporcionar respuestas imprecisas o inadecuadas. Si tienes dudas, presiona el botón "Contactar con un agente humano" para confirmar las respuestas de Lisa Beta 2.0.<br></br><br></br>

    Lisa Beta 2.0 se perfeccionará con tus comentarios<br></br><br></br>

    Asegúrate de calificar las respuestas y reportar cualquier contenido ofensivo o inseguro.<br></br><br></br>

    Infórmate sobre las actualizaciones y mejoras de Lisa Beta 2.0<br></br><br></br>

    Elige recibir noticias por correo electrónico sobre Lisa Beta 2.0. AFP CRECER procesará tus datos personales  para enviar dichos correos, conforme a la Política de Privacidad de AFP CRECER.</div>


export const terms = <p>
Términos y Privacidad 

Tus datos y LISA 

Este aviso y nuestra Política de Privacidad describen cómo LISA maneja tus datos. Por favor, léelos cuidadosamente. 

 

CRECERLAB recoge tus conversaciones con LISA, información relacionada con el uso del producto, la zona general en la que te encuentras y tus comentarios. CRECERLAB utiliza estos datos, de acuerdo con nuestra Política de Privacidad, para proporcionar, mejorar y desarrollar productos y servicios de CRECERLAB y tecnologías de aprendizaje automático. 

 

Por defecto, CRECERLAB almacena esta información con tu cuenta de CRECERLAB durante hasta 18 meses. Para ayudar con la calidad y mejorar nuestros productos, revisores humanos leen, anotan y procesan tus conversaciones con LISA. Tomamos medidas para proteger tu privacidad como parte de este proceso. Esto incluye desconectar tus conversaciones con LISA de tu cuenta de CRECERLAB antes de que los revisores las vean o las anoten. Por favor, no incluyas información que pueda ser utilizada para identificarte a ti o a otros en tus conversaciones con LISA. 

 

Si quieres usar LISA sin guardar tus conversaciones en tu cuenta de CRECERLAB, puedes pausar el guardado de tu actividad con LISA. Las conversaciones con LISA que han sido revisadas o anotadas por revisores humanos no se borran cuando borras tu actividad con LISA porque se guardan por separado y no están conectadas a la cuenta de tu empresa. En su lugar, se conservan hasta por tres años. 

 

Consulta las preguntas frecuentes de LISA para aprender más sobre cómo funciona LISA y cómo CRECERLAB utiliza tus datos de LISA. 

 

Cosas que debes saber 

 

LISA utiliza tus conversaciones pasadas y la zona general en la que te encuentras para proporcionarte su mejor respuesta. 

 

LISA es una tecnología experimental y a veces puede dar información inexacta o inapropiada que no representa las opiniones de CRECERLAB. 

 

No te bases en las respuestas de LISA para asesoramiento médico, legal, financiero u otro tipo de asesoramiento profesional. 

 

No incluyas información confidencial o sensible en tus conversaciones con LISA. 

 

Tus comentarios ayudarán a mejorar a LISA. 

 

Términos de Servicio 

 

Aceptas que tu uso de LISA está sujeto a los Términos de Servicio del sitio web de CRECERLAB y a los Términos de Servicio Adicionales de IA Generativa. 

 

TÉRMINOS DE SERVICIO ADICIONALES DE IA GENERATIVA 

 

Última modificación: 02 de abril de 2024 

 

Para usar los servicios del LISA que hacen referencia a estos términos (los "Servicios"), debes aceptar (1) los Términos de Servicio del website de CRECERLAB, y (2) estos Términos de Servicio Adicionales de IA Generativa. 

 

Por favor, lee estos documentos cuidadosamente. Juntos, estos documentos son conocidos como los "Términos". Establecen qué puedes esperar de nosotros al usar nuestros servicios y qué esperamos de ti. 

 

También te animamos a leer nuestra Política de Privacidad (y cualquier aviso de privacidad presentado en conexión con un Servicio) para entender mejor qué información recopilamos y cómo puedes actualizar, gestionar, exportar y eliminar tu información. 

 

Requisitos de edad 

 

Debes tener 18 años de edad o más para usar los Servicios de LISA. 

 

Restricciones de uso 

 

No puedes usar los Servicios de LISA para desarrollar modelos de aprendizaje automático o tecnología relacionada. 

 

Debes cumplir con nuestra Política de Uso Prohibido, que proporciona detalles adicionales sobre la conducta apropiada al usar los Servicios. 

 

Los Servicios de LISA incluyen características de seguridad para bloquear contenido perjudicial, como contenido que viola nuestras Política de Uso del sitio Web de CrecerLab. No debes intentar eludir estas medidas de protección o usar contenido que viola estos Términos. 

 

No deberás introducir ninguna información personal o sensible, incluyendo nombres, números de teléfono, direcciones, correos electrónicos, o fechas de nacimiento. 

 

Descargos de responsabilidad 

 

Los Servicios de LISA utilizan tecnología experimental y pueden a veces proporcionar contenido inexacto u ofensivo que no representa las opiniones de CRECERLAB. 

 

Usa tu criterio antes de confiar, publicar, o de otra manera usar contenido proporcionado por los Servicios de LISA. 

No te bases en los Servicios de LISA para consejos médicos, legales, financieros u otro tipo de asesoramiento profesional.
Cualquier contenido sobre estos temas se proporciona sólo con fines informativos y no es un sustituto de la asesoría de un profesional cualificado. 
</p>